import { lazy } from "react";

// project-imports
import MainLayout from "layout/MainLayout";
import CommonLayout from "layout/CommonLayout";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";

import UserList from "pages/apps/UserList";
import DemandasGest from "pages/apps/DemandasGest";
import RankingArtistas from "pages/apps/RankingArtistas";
import MinhasSubmissoes from "pages/apps/MinhasSubmissoes";
import ObrasGravadas from "pages/apps/ObrasGravadas";
import ObrasNaoGravadas from "pages/apps/ObrasNaoGravadas";
import DemandasAbertas from "pages/apps/DemandasAbertas";
import FinishRegister from "pages/apps/FinishRegister";
import Inventory from "pages/apps/Inventory";
import Catalog from "pages/apps/Catalog";
import Contactos from "pages/apps/Contactos";

const MaintenanceError = Loadable(
  lazy(() => import("pages/maintenance/error/404"))
);
const MaintenanceError500 = Loadable(
  lazy(() => import("pages/maintenance/error/500"))
);
const MaintenanceUnderConstruction = Loadable(
  lazy(() => import("pages/maintenance/under-construction/under-construction"))
);
const MaintenanceComingSoon = Loadable(
  lazy(() => import("pages/maintenance/coming-soon/coming-soon"))
);

const UserProfile = Loadable(lazy(() => import("pages/apps/user")));
const UserTabPersonal = Loadable(
  lazy(() => import("sections/apps/profiles/user/TabPersonal"))
);
const UserTabPayment = Loadable(
  lazy(() => import("sections/apps/profiles/user/TabPayment"))
);
const UserTabPassword = Loadable(
  lazy(() => import("sections/apps/profiles/user/TabPassword"))
);
const UserTabSettings = Loadable(
  lazy(() => import("sections/apps/profiles/user/TabSettings"))
);
// render - sample page
const SamplePage = Loadable(
  lazy(() => import("pages/extra-pages/sample-page"))
);
const Landing = Loadable(lazy(() => import("pages/landing")));

// ==============================|| MAIN ROUTES ||============================== //

const MainRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "menu-principal",
          element: <SamplePage />,
        },
        {
          path: 'gestao-usuarios',
          element: <UserList />
        },
        {
          path: "gestao-demandas",
          element: <DemandasGest></DemandasGest>
        },
        {
          path: "perfil",
          element: <UserProfile />,
          children: [
            {
              path: "pessoal",
              element: <UserTabPersonal />,
            },
            {
              path: "pagamento",
              element: <UserTabPayment />,
            },
            {
              path: "senha",
              element: <UserTabPassword />,
            },
            {
              path: "ajustes",
              element: <UserTabSettings />,
            },
          ],
        },

        {
          path: "ranking-artistas",
          element: <RankingArtistas></RankingArtistas>,
        },
        {
          path: "finalizar-cadastro",
          element: <FinishRegister />,
        },
        {
          path: "minhas-submissoes",
          element: <MinhasSubmissoes />,
        },
        {
          path: "contactos",
          element: <Contactos />,
        },
        {
          path: "obras-gravadas",
          element: <ObrasGravadas></ObrasGravadas>,
        },
        {
          path: "obras-nao-gravadas",
          element: <ObrasNaoGravadas></ObrasNaoGravadas>,
        },
        {
          path: "demandas-abertas",
          element: <DemandasAbertas></DemandasAbertas>,
        },
        {
          path: "inventory",
          element: <Inventory></Inventory>,
        },
        {
          path: "catalog",
          element: <Catalog></Catalog>,
        },

        {
          path: "/",
          element: <SamplePage />,
        },
      ],
    },

    {
      path: "/maintenance",
      element: <CommonLayout />,
      children: [
        {
          path: "404",
          element: <MaintenanceError />,
        },
        {
          path: "500",
          element: <MaintenanceError500 />,
        },
        {
          path: "under-construction",
          element: <MaintenanceUnderConstruction />,
        },
        {
          path: "coming-soon",
          element: <MaintenanceComingSoon />,
        },
      ],
    },
  ],
};

export default MainRoutes;
