import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState, Fragment } from 'react';

// material-ui
import { alpha, useTheme } from '@mui/material/styles';
import {
  Button,
  Chip,
  Dialog,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import EmailForm from '../../../src/components/EmailForm'; // Componente del formulario de correo
// third-party

import { useFilters, useExpanded, useGlobalFilter, useRowSelect, useSortBy, useTable, usePagination } from 'react-table';

// project-imports
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import IconButton from 'components/@extended/IconButton';
import { PopupTransition } from 'components/@extended/Transitions';
import {
  HeaderSort,
  IndeterminateCheckbox,
  TablePagination,
  TableRowSelection
} from 'components/third-party/ReactTable';

import AddContact from 'sections/apps/contact/AddContact';
import ContactView from 'sections/apps/contact/ContactView';
import AlertContactDelete from 'sections/apps/contact/AlertContactDelete';

import makeData from 'data/react-table';
import { renderFilterTypes  } from 'utils/react-table';

// assets
import { Add, Danger, Edit, Element3, Eye,  Message, Trash, User} from 'iconsax-react';



// ==============================|| REACT TABLE ||============================== //

function ReactTable({ columns, data, renderRowSubComponent, handleAdd }) {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const filterTypes = useMemo(() => renderFilterTypes, []);
  const sortBy = { id: 'fatherName', desc: false };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setHiddenColumns,
    visibleColumns,
    rows,
    page,
    gotoPage,
    setPageSize,
    state: { selectedRowIds, pageIndex, pageSize, expanded },
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 10, hiddenColumns: ['avatar', 'email'], sortBy: [sortBy] }
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    if (matchDownSM) {
      setHiddenColumns(['age', 'contact', 'visits', 'email', 'status', 'avatar']);
    } else {
      setHiddenColumns(['avatar', 'email']);
    }
    // eslint-disable-next-line
  }, [matchDownSM]);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);

  useEffect(() => {
    console.log('Responsive adjustment:', matchDownSM);
  }, [matchDownSM]);

  useEffect(() => {
    setSelectedCount(Object.keys(selectedRowIds).length);
  }, [selectedRowIds]);

  const handleAddContact = useCallback(() => {
    setSelectedContact(null);
    setOpenAddDialog(true);
  }, []);

  const handleSaveContact = useCallback((newContact) => {
    console.log('Nuevo contacto guardado:', newContact);
    setOpenAddDialog(false);
  }, []);

  const handleCancelAdd = useCallback(() => {
    setOpenAddDialog(false);
  }, []);

  const handleCloseDeleteDialog = useCallback(() => {
    setOpenDeleteDialog(false);
  }, []);


  const handleOpenDeleteDialog = () => {
    setSelectedCount(Object.keys(selectedRowIds).length); // Número de contactos seleccionados
    setOpenDeleteDialog(true);
  };


  const handleDeleteContacts = () => {
    // eliminar los contactos seleccionados
    console.log('Eliminando contactos seleccionados...');
    setOpenDeleteDialog(false);
  };
  

  return (
    <>
      <Stack spacing={2} sx={{ borderBottom: `1px solid ${theme.palette.divider}`, pb: 2 }}>
        <Stack direction="row" spacing={1} marginLeft={"10px"} paddingTop={"20px"} alignItems="center">
          <Button
            variant="contained"
            startIcon={<User style={{ fontSize: 20, color: theme.palette.primary.main }} />}
            sx={{
              backgroundColor: alpha(theme.palette.primary.main, 0.1),
              color: theme.palette.primary.main,
              '&:hover': { backgroundColor: alpha(theme.palette.primary.main, 0.2) },
            }}
          >
            My Contacts
          </Button>
          <Button
            variant="contained"
            startIcon={<Element3 style={{ fontSize: 20, color: theme.palette.grey[600] }} />}
            sx={{
              backgroundColor: alpha(theme.palette.grey[500], 0.1),
              color: theme.palette.grey[600],
              '&:hover': { backgroundColor: alpha(theme.palette.grey[500], 0.2) },
            }}
          >
            Establishments
          </Button>
        </Stack>
      </Stack>

      <TableRowSelection selected={selectedCount} />
      <Stack spacing={3}>
        <Stack
          direction={matchDownSM ? 'column' : 'row'}
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 3, pb: 0 }}
        >
          <Stack sx={{ fontWeight: '800', fontSize: '30px' }}>Mis Contactos</Stack>
          <Stack direction={matchDownSM ? 'column' : 'row'} alignItems="center" spacing={2}>
            <Button
              variant="contained"
              startIcon={<Trash />}
              color="error"
              onClick={handleOpenDeleteDialog}
              size="small"
            >
              Remove Contacts
            </Button>
            <Button
              variant="contained"
              startIcon={<Add />}
              color="success"
              onClick={handleAddContact}
              size="small"
            >
              Add new Contact
            </Button>
          </Stack>
        </Stack>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow
                key={headerGroup.id}
                {...headerGroup.getHeaderGroupProps()}
                sx={{ '& > th:first-of-type': { width: '58px' } }}
              >
                {headerGroup.headers.map((column) => (
                  <TableCell
                    key={column.id}
                    {...column.getHeaderProps([{ className: column.className }])}
                  >
                    <HeaderSort column={column} sort />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              const rowProps = row.getRowProps();

              return (
                <Fragment key={i}>
                  <TableRow
                    {...row.getRowProps()}
                    onClick={() => {
                      row.toggleRowSelected();
                    }}
                    sx={{
                      cursor: 'pointer',
                      bgcolor: row.isSelected
                        ? alpha(theme.palette.primary.lighter, 0.35)
                        : 'inherit'
                    }}
                  >
                    {row.cells.map((cell) => (
                      <TableCell
                        key={cell.id}
                        {...cell.getCellProps([{ className: cell.column.className }])}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    ))}
                  </TableRow>
                  {row.isExpanded &&
                    renderRowSubComponent({ row, rowProps, visibleColumns, expanded })}
                </Fragment>
              );
            })}
            <TableRow sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
              <TableCell sx={{ p: 2, py: 3 }} colSpan={9}>
                <TablePagination
                  gotoPage={gotoPage}
                  rows={rows}
                  setPageSize={setPageSize}
                  pageSize={pageSize}
                  pageIndex={pageIndex}
                />

              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Stack>

      {/* Diálogo de advertencia */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle sx={{ fontWeight:"800", fontSize:"25px" }} id="delete-dialog-title"> <Danger/> Advertencia</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            ¿Estas seguro de que quieres borrar {selectedCount} contacto(s)? 
            No se puede deshacer esta opción.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleDeleteContacts} color="error" variant="contained">
            Eliminar Contactos
          </Button>
        </DialogActions>
      </Dialog>

      {/* Diálogo para agregar contacto */}
      {openAddDialog && (
        <Dialog
          open={openAddDialog}
          onClose={handleCancelAdd}
          fullWidth
          maxWidth="sm"
          TransitionComponent={PopupTransition}
        >
          <AddContact
            contact={selectedContact}
            onCancel={handleCancelAdd}
            onSave={handleSaveContact}
          />
        </Dialog>
      )}
    </>
  );
}

ReactTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  getHeaderProps: PropTypes.func,
  handleAdd: PropTypes.func,
  renderRowSubComponent: PropTypes.any
};

// ==============================|| CONTACT - LIST ||============================== //

const Contactos = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;
  const data = useMemo(() => makeData(200), []); // Asegúrate de definir makeData o importar desde donde lo uses
  const [openEmailForm, setOpenEmailForm] = useState(false); // Estado para abrir el formulario de correo
  const [selectedEmail, setSelectedEmail,] = useState('');
  const [selectedFirstName, setSelectedFirstName] = useState(''); // Almacena el email seleccionado
  const [add, setAdd] = useState(false);
  const [contact, setContact] = useState(null);
  const [contactDeleteId, setContactDeleteId] = useState('');
  const [open, setOpen] = useState(false);

  // Función para abrir el formulario y pasar el email
  const handleOpenEmailForm = (email, firstName) => {
    setSelectedEmail(email);
    setSelectedFirstName(firstName);
    setOpenEmailForm(true);
  };

  // Función para cerrar el formulario
  const handleCloseEmailForm = () => {
    setOpenEmailForm(false);
  };

  // Función para enviar el correo (simulada en este caso)
  const handleSendEmail = (emailData) => {
    console.log('Enviando correo a', emailData.to);
    console.log('Asunto:', emailData.subject);
    console.log('Mensaje:', emailData.message);
    console.log('Archivos adjuntos:', emailData.attachments);
  };

  // Función para agregar un nuevo contacto (toggle para la vista)
  const handleAdd = () => {
    setAdd(!add);
    if (contact && !add) setContact(null);
  };

  // Función para cerrar el diálogo de eliminación
  const handleClose = () => {
    setOpen(!open);
  };

  // Definición de las columnas de la tabla
  const columns = useMemo(() => [
    {
      title: 'Row Selection',
      Header: ({ getToggleAllPageRowsSelectedProps }) => <IndeterminateCheckbox indeterminate {...getToggleAllPageRowsSelectedProps()} />,
      accessor: 'selection',
      Cell: ({ row }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
      disableSortBy: true
    },
    {
      Header: 'Name',
      accessor: 'firstName',
      Cell: ({ row }) => {
        const { values } = row;
        return (
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Stack spacing={0}>
              <Typography variant="subtitle1">{values.firstName}</Typography>
              <Typography color="text.secondary">{values.email}</Typography>
            </Stack>
          </Stack>
        );
      }
    },
    {
      Header: 'LastName',
      accessor: 'lastName',
      Cell: ({ row }) => {
        const { values } = row;
        return (
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Stack spacing={0}>
              <Typography variant="subtitle1">{values.lastName}</Typography>
            </Stack>
          </Stack>
        );
      }
    },
    {
      Header: 'Status',
      accessor: 'status2',
      Cell: ({ value }) => {
        switch (value) {
          case 'Inactive':
            return <Chip color="error" label="Inactive" size="small" variant="light" />;
          case 'Active':
            return <Chip color="success" label="Active" size="small" variant="light" />;
          default:
            return <Chip color="info" label="Unknown" size="small" variant="light" />;
        }
      }
    },
    {
      Header: 'Type',
      accessor: 'type',
      Cell: ({ value }) => {
        switch (value) {
          case 'Bar':
            return <Chip color="info" label="Bar" size="small" variant="light" />;
          case 'Suplier':
            return <Chip color="warning" label="Suplier" size="small" variant="light" />;
          case 'Importer':
            return <Chip color="primary" label="Importer" size="small" variant="light" />;
          default:
            return <Chip color="default" label="Unknown" size="small" variant="light" />;
        }
      }
    },
    {
      Header: 'Establishment',
      accessor: 'establishment',
      Cell: ({ value }) => {
        return <span>{value}</span>;
      }
    },
    {
      Header: 'Last Interaction',
      accessor: 'lastInteraction',
      Cell: ({ value }) => {
        return <span>{value}</span>;
      }
    },
    {
      Header: 'Contact',
      accessor: 'contact',
      Cell: ({ row }) => {
        return (
          <IconButton
            color="primary"
            onClick={() => handleOpenEmailForm(row.values.email)} // Abrir el formulario de email
          >
            <Message />
          </IconButton>
        );
      }
    },
    {
      Header: 'View More',
      className: 'cell-center',
      disableSortBy: true,
      Cell: ({ row }) => {
        const collapseIcon = row.isExpanded ? <Add style={{ color: theme.palette.error.main, transform: 'rotate(45deg)' }} /> : <Eye />;
        return (
          <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
            <Tooltip title="View">
              <IconButton
                color="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  row.toggleRowExpanded();
                }}
              >
                {collapseIcon}
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  setContact(row.values);
                  handleAdd();
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                color="error"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClose();
                  setContactDeleteId(row.values.id);
                }}
              >
                <Trash />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      }
    }
  ], [theme]);

  // Función para renderizar las filas expandidas
  const renderRowSubComponent = useCallback(({ row }) => <ContactView data={data[Number(row.id)]} />, [data]);

  return (
    <MainCard content={false}>
      <ScrollX>
        <ReactTable columns={columns} data={data} renderRowSubComponent={renderRowSubComponent} />
      </ScrollX>

      {/* Formulario de correo */}
      <EmailForm
        open={openEmailForm}
        onClose={handleCloseEmailForm}
        onSend={handleSendEmail}
        email={selectedEmail}
        firstName={selectedFirstName}
      />

      {/* Diálogo para agregar un nuevo contacto */}
      <Dialog
        maxWidth="sm"
        TransitionComponent={PopupTransition}
        keepMounted
        fullWidth
        onClose={handleAdd}
        open={add}
        sx={{ '& .MuiDialog-paper': { p: 0 }, transition: 'transform 225ms' }}
      >
        <AddContact customer={contact} onCancel={handleAdd} />
      </Dialog>

      {/* Alerta de eliminación de contacto */}
      <AlertContactDelete title={contactDeleteId} open={open} handleClose={handleClose} />
    </MainCard>
  );
};

Contactos.propTypes = {
  row: PropTypes.object,
  values: PropTypes.object,
  avatar: PropTypes.object,
  message: PropTypes.string,
  fatherName: PropTypes.string,
  email: PropTypes.string,
  value: PropTypes.object,
  isExpanded: PropTypes.bool,
  toggleRowExpanded: PropTypes.func,
  getToggleAllPageRowsSelectedProps: PropTypes.func,
  id: PropTypes.number
};

export default Contactos;
