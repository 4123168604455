// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { DocumentCode2, OceanProtocol, Level, ShieldCross, InfoCircle, I24Support, Driving,Profile2User, MusicLibrary2
} from 'iconsax-react';

// icons
const icons = {
  samplePage: DocumentCode2,
  menuLevel: OceanProtocol,
  menuLevelSubtitle: Level,
  disabledMenu: ShieldCross,
  chipMenu: InfoCircle,
  documentation: I24Support,
  roadmap: Driving,
  users: Profile2User,
  MusicLibrary2:MusicLibrary2

};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const ADMIN = {
  id: 'admin',
  title: <FormattedMessage id="Gestão Admin" />,
  type: 'group',
  children: [
   
    {
      id: 'usuarios1',
      title: <FormattedMessage id="Usuários" />,
      type: 'item',
      url: 'gestao-usuarios',
      icon: icons.users,
     
      chip: {
        label: 'ADM',
        color: 'error',
        variant: 'outlined',
        size: 'small'
      }
    },
    {
      id: 'demandas1',
      title: <FormattedMessage id="Demandas" />,
      type: 'item',
      url: 'gestao-demandas',
      icon: icons.MusicLibrary2,
      disabled:true,
     
      chip: {
        label: 'ADM',
        color: 'error',
        variant: 'outlined',
        size: 'small'
      }
    },
  ] 
};

export default ADMIN;
