// project-imports
import Hero from 'sections/landing/Header';
import Technologies from 'sections/landing/Technologies';
import Combo from 'sections/landing/Combo';
import Apps from 'sections/landing/Apps';
import Free from 'sections/landing/Free';
import Testimonial from 'sections/landing/Testimonial';
import Partner from 'sections/landing/Partner';
import ContactUs from 'sections/landing/ContactUs';
import { useGoogleOneTapLogin } from 'react-google-one-tap-login';
import { useEffect, useState } from 'react';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { APICall } from 'api';
import useAuth from 'hooks/useAuth';
import { useNavigate } from 'react-router';
import { APP_DEFAULT_PATH_AUTHED } from 'config';

// ==============================|| SAMPLE PAGE ||============================== //

const Landing = () => {
  const { isLoggedIn,socialLogin } = useAuth();
  const [cancel_on_tap_outside, setCancel_on_tap_outside] = useState(true);


  const navigator = useNavigate()
  useGoogleOneTapLogin({
    googleAccountConfigs: {
      client_id:
        "1011838355648-6mgc6a6em4jmq7ltnufbtsakt74d9ht6.apps.googleusercontent.com",
      callback: ({ clientId, credential, select_by }) =>
        handleLogin(credential),
    },
    cancel_on_tap_outside: cancel_on_tap_outside,
    disabled: isLoggedIn,
  });
console.log("isLoggedIn: "+isLoggedIn)

  useEffect(() => {
    setTimeout(() => {
      setCancel_on_tap_outside(false);
    }, 300);
  }, []);


  const handleLogin = (credential) => {

    APICall.oneTapGoogleSignIn(credential)
      .then((res) => {
       
        if(res.message == "OK"){
          socialLogin(res.user,res.token)
          dispatch(
            openSnackbar({
              open: true,
              message: 'Conectado com sucesso!',
              variant: 'alert',
              alert: {
                color: 'success'
              },
              close: false,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            })
          )
          navigator(APP_DEFAULT_PATH_AUTHED)
        }


      })
      .catch((res) => {
        console.log(res);
        dispatch(
          openSnackbar({
            open: true,
            message: 'Erro ao fazer login',
            variant: 'alert',
            alert: {
              color: 'error'
            },
            close: false,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          })
        )
      })
    
  };

  
  return (
    <>
      <Hero />
      {/* <Technologies /> */}
      {/* <Combo />
      <Apps /> */}
      {/* <Free /> */}
      {/* <Partner /> */}
      {/* <Testimonial /> */}
     
      {/* <ContactUs /> */}
    </>
  );
};

export default Landing;
