// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { DocumentCode2, OceanProtocol, Level, ShieldCross, InfoCircle, I24Support, Driving, ArchiveBox, MusicPlay } from 'iconsax-react';

// icons
const icons = {
  samplePage: DocumentCode2,
  menuLevel: OceanProtocol,
  menuLevelSubtitle: Level,
  disabledMenu: ShieldCross,
  chipMenu: InfoCircle,
  documentation: I24Support,
  roadmap: Driving,
  ArchiveBox:ArchiveBox,
  MusicPlay:MusicPlay
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const Diretorio = {
  id: 'diretorio',
  title: <FormattedMessage id="Diretórios" />,
  type: 'group',
  children: [
   
    {
      id: 'obras-grav-menu1',
      title: <FormattedMessage id="Obras Gravadas " />,
      type: 'item',
      url: '/Obras-Gravadas',
      icon: icons.MusicPlay,
      disabled: false
    },
    {
      id: 'obras-n-grav-menu1',
      title: <FormattedMessage id="Obras Não Gravadas " />,
      type: 'item',
      url: '/Obras-nao-Gravadas',
      icon: icons.ArchiveBox,
      disabled: true
    },
  ] 
};

export default Diretorio;
