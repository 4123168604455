// project-imports
import axios from 'utils/axios';
import { dispatch } from 'store';

// third-party
import { createSlice } from '@reduxjs/toolkit';

const countries = [
  { code: 'US', label: 'United States Dollar', currency: 'Dollar', prefix: '$' },
  { code: 'GB', label: 'United Kingdom Pound', currency: 'Pound', prefix: '£' },
  { code: 'IN', label: 'India Rupee', currency: 'Rupee', prefix: '₹' },
  { code: 'JP', label: 'Japan Yun', currency: 'Yun', prefix: '¥' }
];

const initialState = {
  isOpen: false,
  isCustomerOpen: false,
  open: false,
  country: countries[2],
  countries: countries,
  lists: [],
  list: null,
  error: null,
  alertPopup: false
};

// ==============================|| SLICE - INVOICE ||============================== //

const invoice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    // review invoice popup
    reviewInvoicePopup(state, action) {
      state.isOpen = action.payload.isOpen;
    },

    // is customer open
    customerPopup(state, action) {
      state.isCustomerOpen = action.payload.isCustomerOpen;
    },

    // handler customer form popup
    toggleCustomerPopup(state, action) {
      state.open = action.payload.open;
    },

    // handler customer form popup
    selectCountry(state, action) {
      state.country = action.payload.country;
    },

    hasError(state, action) {
      state.error = action.payload.error;
    },

    // get all invoice list
    getLists(state, action) {
      state.lists = action.payload;
    },

    // get invoice details
    getSingleList(state, action) {
      state.list = action.payload;
    },

    // create invoice
    createInvoice(state, action) {
      state.lists = [...state.lists, action.payload];
    },

    // update invoice
    UpdateInvoice(state, action) {
      const list = action.payload;
      const InvoiceUpdate = state.lists.map((item) => {
        if (item.id === list.id) {
          return list;
        }
        return item;
      });
      state.lists = InvoiceUpdate;
    },

    // delete invoice
    deleteInvoice(state, action) {
      const { invoiceId } = action.payload;
      const deleteInvoice = state.lists.filter((list) => list.id !== invoiceId);
      state.lists = deleteInvoice;
    },

    //alert popup
    alertPopupToggle(state, action) {
      state.alertPopup = action.payload.alertToggle;
    }
  }
});

export default invoice.reducer;

export const { reviewInvoicePopup, customerPopup, toggleCustomerPopup, selectCountry, getLists, alertPopupToggle } = invoice.actions;

export function getInvoiceList() {
  return async () => {
  
      const response = {
        "invoice": [
            {
                "id": 1,
                "invoice_id": 8795646525451,
                "customer_name": "Tessi Eneas",
                "email": "tass23@gmail.com",
                "avatar": 5,
                "discount": 0.5,
                "tax": 0.2,
                "date": "05/01/2022",
                "due_date": "06/02/2022",
                "quantity": 1000,
                "status": "Paid",
                "notes": "",
                "cashierInfo": {
                    "name": "Ian Carpenter",
                    "address": "1754 Ureate, RhodSA5 5BO",
                    "phone": "+91 1234567890",
                    "email": "iacrpt65@gmail.com"
                },
                "customerInfo": {
                    "name": "Belle J. Richter",
                    "address": "1300 Mine RoadQuemado, NM 87829",
                    "phone": "305-829-7809",
                    "email": "belljrc23@gmail.com"
                },
                "invoice_detail": [
                    {
                        "id": 2,
                        "name": "Apple Series 4 GPS A38 MM Space",
                        "qty": 3,
                        "description": "Apple Watch SE Smartwatch",
                        "price": 275
                    },
                    {
                        "id": 3,
                        "name": "Boat On-Ear Wireless",
                        "description": "Mic(Bluetooth 4.2, Rockerz 450R...",
                        "qty": 45,
                        "price": 81.99
                    },
                    {
                        "id": 4,
                        "name": "Fitbit MX30 Smart Watch",
                        "description": "(MX30- waterproof) watch",
                        "qty": 70,
                        "price": 85
                    }
                ]
            },
            {
                "id": 2,
                "invoice_id": 8795646525452,
                "customer_name": "Abey Boseley",
                "email": "aabsl32@gmail.com",
                "avatar": 4,
                "date": "7/15/2022",
                "due_date": "2/15/2022",
                "quantity": 2030,
                "discount": 0,
                "tax": 0.8,
                "status": "Unpaid",
                "notes": "",
                "cashierInfo": {
                    "name": "Belle J. Richter",
                    "address": "1300 Mine RoadQuemado, NM 87829",
                    "phone": "305-829-7809",
                    "email": "belljrc23@gmail.com"
                },
                "customerInfo": {
                    "name": "Ian Carpenter",
                    "address": "1754 Ureate, RhodSA5 5BO",
                    "phone": "+91 1234567890",
                    "email": "iacrpt65@gmail.com"
                },
                "invoice_detail": [
                    {
                        "id": 6,
                        "name": "Luxury Watches Centrix Gold",
                        "description": "7655 Couple (Refurbished)...",
                        "qty": 3,
                        "price": 29.99
                    },
                    {
                        "id": 7,
                        "name": "Canon EOS 1500D 24.1 Digital SLR",
                        "description": "SLR Camera (Black) with EF S18-55...",
                        "qty": 50,
                        "price": 12.99
                    }
                ]
            },
            {
                "id": 3,
                "invoice_id": 8795646525453,
                "customer_name": "Shelba Thews",
                "email": "slbt37@gmail.com",
                "avatar": 7,
                "date": "7/6/2022",
                "due_date": "7/8/2022",
                "quantity": 3000,
                "discount": 1,
                "tax": 2,
                "status": "Cancelled",
                "notes": "",
                "cashierInfo": {
                    "name": "Ritika Yohannan",
                    "address": "3488 Arbutus DriveMiami, FL",
                    "phone": "+91 1234567890",
                    "email": "rtyhn65@gmail.com"
                },
                "customerInfo": {
                    "name": "Thomas D. Johnson",
                    "address": "4388 House DriveWestville, OH +91",
                    "phone": "1234567890",
                    "email": "thomshj56@gmail.com"
                },
                "invoice_detail": [
                    {
                        "id": 9,
                        "name": "Apple iPhone 13 Mini ",
                        "description": "13 cm (5.4-inch) Super",
                        "qty": 40,
                        "price": 86.99
                    },
                    {
                        "id": 10,
                        "name": "Apple MacBook Pro with Iphone",
                        "description": "11th Generation Intel® Core™ i5-11320H ...",
                        "qty": 70,
                        "price": 14.59
                    },
                    {
                        "id": 11,
                        "name": "Apple iPhone 13 Pro",
                        "description": "(512GB ROM, MLLH3HN/A,..",
                        "qty": 21,
                        "price": 100
                    },
                    {
                        "id": 12,
                        "name": "Canon EOS 1500D 24.1 Digital",
                        "description": "(512GB ROM, MLLH3HN/A,..",
                        "qty": 21,
                        "price": 399
                    }
                ]
            },
            {
                "id": 4,
                "invoice_id": 8795646525454,
                "customer_name": "Salvatore Boncore",
                "email": "sabf231@gmail.com",
                "avatar": 8,
                "date": "2/8/2022",
                "due_date": "3/30/2022",
                "quantity": 2000,
                "discount": 0.89,
                "tax": 5.2,
                "status": "Unpaid",
                "notes": "",
                "cashierInfo": {
                    "name": "Jesse G. Hassen",
                    "address": "3488 Arbutus DriveMiami, FL 33012",
                    "phone": "+91 1234567890",
                    "email": "jessghs78@gmail.com"
                },
                "customerInfo": {
                    "name": "Christopher P. Iacovelli",
                    "address": "4388 House DriveWesrville, OH",
                    "phone": "+91 1234567890",
                    "email": "crpthl643@gmail.com"
                },
                "invoice_detail": [
                    {
                        "id": 14,
                        "name": "Luxury Watches Centrix Gold",
                        "description": "7655 Couple (Refurbished)...",
                        "qty": 3,
                        "price": 29.99
                    },
                    {
                        "id": 15,
                        "name": "Canon EOS 1500D 24.1 Digital SLR",
                        "description": "SLR Camera (Black) with EF S18-55...",
                        "qty": 50,
                        "price": 12.99
                    },
                    {
                        "id": 16,
                        "name": "Apple iPhone 13 Pro",
                        "description": "(512GB ROM, MLLH3HN/A,..",
                        "qty": 21,
                        "price": 100
                    },
                    {
                        "id": 17,
                        "name": "Canon EOS 1500D 24.1 Digital",
                        "description": "(512GB ROM, MLLH3HN/A,..",
                        "qty": 21,
                        "price": 399
                    }
                ]
            },
            {
                "id": 5,
                "invoice_id": 8795646525455,
                "customer_name": "Mickie Melmoth",
                "email": "mmsht23@gmail.com",
                "avatar": 2,
                "discount": 0.1,
                "tax": 0.52,
                "date": "5/5/2022",
                "due_date": "7/11/2022",
                "quantity": 3000,
                "status": "Paid",
                "notes": "",
                "cashierInfo": {
                    "name": "Thomas D. Johnson",
                    "address": "4388 House DriveWestville, OH +91",
                    "phone": "1234567890",
                    "email": "thomshj56@gmail.com"
                },
                "customerInfo": {
                    "name": "Ian Carpenter",
                    "address": "1754 Ureate, RhodSA5 5BO",
                    "phone": "+91 1234567890",
                    "email": "iacrpt65@gmail.com"
                },
                "invoice_detail": [
                    {
                        "id": 19,
                        "name": "Luxury Watches Centrix Gold",
                        "description": "7655 Couple (Refurbished)...",
                        "qty": 3,
                        "price": 29.99
                    },
                    {
                        "id": 20,
                        "name": "Canon EOS 1500D 24.1 Digital SLR",
                        "description": "SLR Camera (Black) with EF S18-55...",
                        "qty": 50,
                        "price": 12.99
                    },
                    {
                        "id": 21,
                        "name": "Luxury Watches sliver",
                        "description": "7655 Couple (Refurbished)...",
                        "qty": 3,
                        "price": 29.99
                    },
                    {
                        "id": 22,
                        "name": "Canon EOS 1800D",
                        "description": "SLR Camera (Black) with EF S18-55...",
                        "qty": 50,
                        "price": 12.99
                    },
                    {
                        "id": 23,
                        "name": "Apple watch 5 series",
                        "qty": 3,
                        "description": "Apple Watch SE Smartwatch",
                        "price": 275
                    }
                ]
            }
        ]
    }
      dispatch(invoice.actions.getLists(response.invoice));
 
  };
}

export function getInvoiceInsert(NewLists) {
  return async () => {
    try {
      const response = await axios.post('/api/invoice/insert', { list: NewLists });
      dispatch(invoice.actions.createInvoice(response.data));
    } catch (error) {
      dispatch(invoice.actions.hasError(error));
    }
  };
}

export function getInvoiceUpdate(NewLists) {
  return async () => {
    try {
      const response = await axios.post('/api/invoice/update', { list: NewLists });
      dispatch(invoice.actions.UpdateInvoice(response.data));
    } catch (error) {
      dispatch(invoice.actions.hasError(error));
    }
  };
}

export function getInvoiceDelete(invoiceId) {
  return async () => {
    try {
      await axios.post('/api/invoice/delete', { invoiceId });
      dispatch(invoice.actions.deleteInvoice({ invoiceId }));
    } catch (error) {
      dispatch(invoice.actions.hasError(error));
    }
  };
}

export function getInvoiceSingleList(invoiceId) {
  return async () => {
    try {
      const response = await axios.post('/api/invoice/single', { id: invoiceId });
      dispatch(invoice.actions.getSingleList(response.data));
    } catch (error) {
      dispatch(invoice.actions.hasError(error));
    }
  };
}
