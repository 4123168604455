// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { DocumentCode2, OceanProtocol, Level, ShieldCross, InfoCircle, I24Support, Driving } from 'iconsax-react';

// icons
const icons = {
  samplePage: DocumentCode2,
  menuLevel: OceanProtocol,
  menuLevelSubtitle: Level,
  disabledMenu: ShieldCross,
  chipMenu: InfoCircle,
  documentation: I24Support,
  roadmap: Driving
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const Education = {
  id: 'other',
  title: <FormattedMessage id="Conteúdo & Workshops" />,
  type: 'group',
  children: [
    {
      id: 'menu-level-subtitle',
      title: <FormattedMessage id="Cursos e Workshops" />,
      caption: <FormattedMessage id="Novo Conteúdo Exclusivo" />,
      type: 'collapse',
      icon: icons.menuLevelSubtitle,
      children: [
        {
          id: 'sub-menu-level-1.2',
          title: (
            <>
              <FormattedMessage id="Podcast" /> 
            </>
          ),
          caption: <FormattedMessage id="3 novos Episódios" />,
          type: 'collapse',
          children: [
            {
              id: 'sub-menu-level-2.1',
              title: (
                <>
                  <FormattedMessage id="Out/23 " /> 
                </>
              ),
              caption: <FormattedMessage id="menu-level-subtitle-sub-item" />,
              type: 'item',
              url: '#'
            },
            {
              id: 'sub-menu-level-2.13',
              title: (
                <>
                  <FormattedMessage id="Nov/23 " /> 
                </>
              ),
              caption: <FormattedMessage id="menu-level-subtitle-sub-item" />,
              type: 'item',
              url: '#'
            }
          ]
        },
        {
          id: 'sub-menu-level-1.2',
          title: (
            <>
              <FormattedMessage id="Cursos Gratuitos" /> 
            </>
          ),
          caption: <FormattedMessage id="3 cursos novos" />,
          type: 'collapse',
          children: [
            {
              id: 'sub-menu-level-2.1',
              title: (
                <>
                  <FormattedMessage id="Out/23 " /> 
                </>
              ),
              caption: <FormattedMessage id="menu-level-subtitle-sub-item" />,
              type: 'item',
              url: '#'
            },
            {
              id: 'sub-menu-level-2.13',
              title: (
                <>
                  <FormattedMessage id="Nov/23 " /> 
                </>
              ),
              caption: <FormattedMessage id="menu-level-subtitle-sub-item" />,
              type: 'item',
              url: '#'
            }
          ]
        },
        {
          id: 'sub-menu-level-1.24',
          title: (
            <>
              <FormattedMessage id="Cursos Pagos" /> 
            </>
          ),
          caption: <FormattedMessage id="5 novos disponíveis" />,
          type: 'collapse',
          children: [
            {
              id: 'sub-menu-level-2.15',
              title: (
                <>
                  <FormattedMessage id="Out/23 " /> 
                </>
              ),
              caption: <FormattedMessage id="menu-level-subtitle-sub-item" />,
              type: 'item',
              url: '#'
            },
            {
              id: 'sub-menu-level-2.16',
              title: (
                <>
   <FormattedMessage id="Nov/23 " /> 
                </>
              ),
              caption: <FormattedMessage id="menu-level-subtitle-sub-item" />,
              type: 'item',
              url: '#'
            }
          ]
        },
      ]
    },
   
   
  ]
};

export default Education;
