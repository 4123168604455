import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Stack,
  Typography,
  Box
} from '@mui/material';

const EmailForm = ({ open, onClose, onSend, email, firstName }) => {
  const [formData, setFormData] = useState({
    to: email || '',
    subject: '',
    message: '',
    attachments: null
  });

  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFormData((prev) => ({ ...prev, attachments: e.target.files }));
  };

  const handleSend = () => {
    onSend(formData);
    setShowConfirmation(true); // Muestra la vista de confirmación
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
    onClose(); // Cierra el formulario
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      {showConfirmation ? (
        <DialogContent>
          <Typography variant="h5" align="center" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
            Tu mensaje ha sido enviado.
          </Typography>
          <Typography variant="body1" align="center" sx={{ marginBottom: 2 }}>
            Hemos enviado el mensaje con éxito a <strong>{firstName || 'nombre de contacto'}</strong>.
          </Typography>
          <Typography variant="body1" align="center">
            Te notificaremos de su respuesta por correo electrónico y por la plataforma.
          </Typography>
          <Stack direction="row" justifyContent="center" sx={{ marginTop: 3 }}>
            <Button
              onClick={handleCloseConfirmation}
              variant="contained"
              sx={{
                padding: '5px 30px',
                borderRadius: 2,
                backgroundColor: '#F0531C',
                color: 'white',
                '&:hover': { backgroundColor: 'darkorange' }
              }}
            >
              Aceptar
            </Button>
          </Stack>
        </DialogContent>
      ) : (
        <>
          <DialogTitle sx={{ fontWeight: '800', fontSize: '30px' }}>Mensaje Nuevo</DialogTitle>
          <DialogContent sx={{  paddingTop: "10px"}}>
            <Stack spacing={2}>
              <TextField
                label="Para"
                name="to"
                value={formData.to}
                onChange={handleChange}
                fullWidth
                size="small"
              />
              <TextField
                label="Asunto"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                fullWidth
                size="small"
              />
              <TextField
                label="Mensaje"
                name="message"
                value={formData.message}
                onChange={handleChange}
                fullWidth
                multiline
                rows={4}
                size="small"
              />
              <Box>
                <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                  Añadir Archivos Adjuntos
                </Typography>
                <Box
                  sx={{
                    border: '2px dashed #ccc',
                    padding: 2,
                    textAlign: 'center',
                    borderRadius: 4,
                    backgroundColor: '#f9f9f9',
                    cursor: 'pointer'
                  }}
                >
                  <Typography variant="body2" color="text.secondary">
                    Upload or drag and drop files here
                  </Typography>
                  <input
                    type="file"
                    multiple
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                </Box>
              </Box>
              <Stack direction="row" justifyContent="center" spacing={2}>
                <Button
                  onClick={onClose}
                  variant="outlined"
                  sx={{
                    padding: '5px 55px',
                    borderRadius: 2,
                    borderColor: 'gray',
                    color: 'gray',
                    '&:hover': { borderColor: 'darkgray', backgroundColor: '#f5f5f5' }
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSend}
                  variant="contained"
                  sx={{
                    padding: '5px 55px',
                    borderRadius: 2,
                    backgroundColor: '#F0531C',
                    color: 'white',
                    '&:hover': { backgroundColor: 'darkorange' }
                  }}
                >
                  Send
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};



export default EmailForm;