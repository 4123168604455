// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { DocumentCode2, OceanProtocol, Level, ShieldCross, InfoCircle, I24Support, Driving, MusicSquareSearch, MusicSquareAdd, MusicCircle } from 'iconsax-react';

// icons
const icons = {
  samplePage: DocumentCode2,
  menuLevel: OceanProtocol,
  menuLevelSubtitle: Level,
  disabledMenu: ShieldCross,
  chipMenu: InfoCircle,
  documentation: I24Support,
  roadmap: Driving,
  MusicSquareSearch:MusicSquareSearch,
  MusicSquareAdd:MusicSquareAdd,
  MusicCircle:MusicCircle

};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const Demanda = {
  id: 'Demanda',
  title: <FormattedMessage id="Demanda de Conteúdo" />,
  type: 'group',
  children: [
   
    {
      id: 'minhas-sub',
      title: <FormattedMessage id="Minhas Submissões " />,
      type: 'item',
      url: '/minhas-submissoes',
      icon: icons.MusicCircle      ,
      disabled: true
    },
    {
      id: 'demandas-aberts',
      title: <FormattedMessage id="Ver Demandas Abertas" />,
      type: 'item',
      url: '/demandas-abertas',
      icon: icons.MusicSquareSearch,
      disabled: true
    },
  ]
};

export default Demanda;
