import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

// third-party
import _ from "lodash";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

// project-imports
import AlertCustomerDelete from "./AlertCustomerDelete";
import Avatar from "components/@extended/Avatar";
import IconButton from "components/@extended/IconButton";
import { dispatch } from "store";
import { openSnackbar } from "store/reducers/snackbar";
import { ThemeMode } from "config";

// assets
import { Camera, Trash } from "iconsax-react";
import SingleFileUpload from "components/third-party/SingleFile";
import useAuth from "hooks/useAuth";
import { APICall } from "api";

const avatarImage = require.context("assets/images/users", true);

// constant
const getInitialValues = (customer) => {
  const newCustomer = {
    name: "",
    email: "",
    location: "",
    orderStatus: "",
  };

  if (customer) {
    newCustomer.name = customer.fatherName;
    newCustomer.location = customer.address;
    return _.merge({}, newCustomer, customer);
  }

  return newCustomer;
};

const allStatus = [
  " Música (M)",
  "Música de Concerto (MC)",
  "Trilha Sonora (TS)",
  "Jingle (J)",
  "Spot (SP)",
  "Vinheta (V)",
  "Tema Musical (TM)",
  "Gingle (G)",
  "Avaliação (AV)",
  "Música Incidental (MI)",
];
const tipos = [
  "Original",
  "Adaptação",
  "Tradução",
  "Arranjo",
  "Versão",
  "Domínio Público",
];
// ==============================|| CUSTOMER - ADD / EDIT ||============================== //

const AddObra = ({ customer, onCancel }) => {
  const theme = useTheme();
  const isCreating = !customer;
  const { user, completeRegister } = useAuth();
  const [selectedImage, setSelectedImage] = useState(undefined);
  const [isLoading, setisLoading] = useState(false);
  const [mp3, setmp3] = useState([]);
  const [avatar, setAvatar] = useState(
    avatarImage(
      `./avatar-${isCreating && !customer?.avatar ? 1 : customer.avatar}.png`
    )
  );

  useEffect(() => {
    if (selectedImage) {
      setAvatar(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const CustomerSchema = Yup.object().shape({
    title: Yup.string().max(255).required("Titulo é obrigatório"),
    subtitle: Yup.string().max(255),
    categoria: Yup.string().required("Categoria é obrigatória"),
    desc: Yup.string().max(500).required("Descrição é obrigatório"),
    lingua: Yup.string().required("Lingua é obrigatório"),
    letra: Yup.string().required("Letra é obrigatório"),
    tipo: Yup.string().required("Tipo é obrigatório"),
  });

  const [openAlert, setOpenAlert] = useState(false);

  const handleAlertClose = () => {
    setOpenAlert(!openAlert);
    onCancel();
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      subtitle: "",
      categoria: "",
      desc: "",
      lingua: "",
      letra: "",
      tipo: "",
    },
    validationSchema: CustomerSchema,
    onSubmit: (values, { setSubmitting }) => {
      try {
        setisLoading(true);
        if (mp3.length > 0) {
          values.mp3 = mp3[0].base64;
        }
        APICall.addObra({ obra: values })
          .then((res) => {
            completeRegister(res.user);
            dispatch(
              openSnackbar({
                open: true,
                message: "Obra Adicionada Com Sucesso",
                variant: "alert",
                alert: {
                  color: "success",
                },
                close: false,
              })
            );

            setSubmitting(false);
            onCancel();
          })
          .catch(() => {
            dispatch(
              openSnackbar({
                open: true,
                message: "Erro ao Adicinar Obra",
                variant: "alert",
                alert: {
                  color: "danger",
                },
                close: false,
              })
            );
          })
          .finally(() => {
            setisLoading(false);
          });
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <DialogTitle>Adicionar Obra</DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="customer-name">Título</InputLabel>
                        <TextField
                          fullWidth
                          id="customer-title"
                          placeholder="Título da obra"
                          {...getFieldProps("title")}
                          error={Boolean(touched.title && errors.title)}
                          helperText={touched.title && errors.title}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="customer-subtitle">
                          Subtítulo
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-subtitle"
                          placeholder="Subtítulo da obra"
                          {...getFieldProps("subtitle")}
                          error={Boolean(touched.subtitle && errors.subtitle)}
                          helperText={touched.subtitle && errors.subtitle}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="customer-orderStatus">
                          Categoria da Obra
                        </InputLabel>
                        <FormControl fullWidth>
                          <Select
                            id="column-hiding"
                            displayEmpty
                            {...getFieldProps("categoria")}
                            onChange={(event) =>
                              setFieldValue("categoria", event.target.value)
                            }
                            input={
                              <OutlinedInput
                                id="select-column-hiding"
                                placeholder="Sort by"
                              />
                            }
                            renderValue={(selected) => {
                              if (!selected) {
                                return (
                                  <Typography variant="subtitle1">
                                    Selecionar Categoria
                                  </Typography>
                                );
                              }

                              return (
                                <Typography variant="subtitle2">
                                  {selected}
                                </Typography>
                              );
                            }}
                          >
                            {allStatus.map((column) => (
                              <MenuItem key={column} value={column}>
                                <ListItemText primary={column} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {touched.categoria && errors.categoria && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-email-login"
                            sx={{ pl: 1.75 }}
                          >
                            {errors.categoria}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="customer-location">
                          Descrição
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-desc"
                          multiline
                          rows={2}
                          placeholder="Escreva uma descrição para a obra"
                          {...getFieldProps("desc")}
                          error={Boolean(touched.desc && errors.desc)}
                          helperText={touched.desc && errors.desc}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="customer-lingua">
                          Lingua da Letra
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-lingua"
                          placeholder="Ingles, Espanhol, Russo..."
                          {...getFieldProps("lingua")}
                          error={Boolean(touched.lingua && errors.lingua)}
                          helperText={touched.lingua && errors.lingua}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="customer-location">
                          Letra da Obra
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-letra"
                          multiline
                          rows={2}
                          placeholder="Escreva a letra da obra"
                          {...getFieldProps("letra")}
                          error={Boolean(touched.letra && errors.letra)}
                          helperText={touched.letra && errors.letra}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="customer-orderStatus">
                          Tipo de Criação
                        </InputLabel>
                        <FormControl fullWidth>
                          <Select
                            id="column-hiding"
                            displayEmpty
                            {...getFieldProps("tipo")}
                            onChange={(event) =>
                              setFieldValue("tipo", event.target.value)
                            }
                            input={
                              <OutlinedInput
                                id="select-column-hiding"
                                placeholder="Sort by"
                              />
                            }
                            renderValue={(selected) => {
                              if (!selected) {
                                return (
                                  <Typography variant="subtitle1">
                                    Selecionar tipo de Criação
                                  </Typography>
                                );
                              }

                              return (
                                <Typography variant="subtitle2">
                                  {selected}
                                </Typography>
                              );
                            }}
                          >
                            {tipos.map((column) => (
                              <MenuItem key={column} value={column}>
                                <ListItemText primary={column} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {touched.tipo && errors.tipo && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-email-login"
                            sx={{ pl: 1.75 }}
                          >
                            {errors.tipo}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>

                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="customer-duracao">
                          Titulares
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-titlar"
                          placeholder="Carregue um arquivo para mostrar a duração"
                          value={
                            "1 Titular: " + user.name + " com 100% dos direitos"
                          }
                          error={Boolean(touched.duracao && errors.duracao)}
                          helperText={touched.duracao && errors.duracao}
                        />
                        <Button
                          //add
                          variant="contained"
                          color="primary"
                          size="large"
                          sx={{ mt: 1 }}
                          disabled
                        >
                          Adicionar Titular
                        </Button>
                      </Stack>
                    </Grid>

                    <Divider></Divider>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="file">
                          Arquivo da Obra (Música)
                        </InputLabel>
                        <SingleFileUpload
                          file={mp3}
                          setFieldValue={(x) => {
                            console.log(x);
                            setmp3(x);
                          }}
                        ></SingleFileUpload>
                      </Stack>
                    </Grid>
                    {/* <Grid item xs={12}>
                      <Stack spacing={1.25}>
                      <InputLabel htmlFor="file">
                        Foto de Capa da Obra 
                        </InputLabel>
                    <SingleFileUpload></SingleFileUpload>
                    </Stack>
                    </Grid> */}
                    <Divider></Divider>

                    <Grid item xs={12}>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                      >
                        <Stack spacing={0.5}>
                          <Typography variant="subtitle1">
                            Obra Publicada
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            Caso a obra ainda não tenha sido publicada, desative
                            essa opção
                          </Typography>
                        </Stack>
                        <FormControlLabel
                          control={<Switch defaultChecked sx={{ mt: 0 }} />}
                          label=""
                          labelPlacement="start"
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  {!isCreating && (
                    <Tooltip title="Delete Customer" placement="top">
                      <IconButton
                        onClick={() => setOpenAlert(true)}
                        size="large"
                        color="error"
                      >
                        <Trash variant="Bold" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Button
                      color="error"
                      onClick={onCancel}
                      disabled={isSubmitting || isLoading}
                    >
                      Cancelar
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting || isLoading }
                    >
                      {(isSubmitting || isLoading) ? "Carregando...":"Adicionar"}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
      {!isCreating && (
        <AlertCustomerDelete
          title={customer.fatherName}
          open={openAlert}
          handleClose={handleAlertClose}
        />
      )}
    </>
  );
};

AddObra.propTypes = {
  customer: PropTypes.any,
  onCancel: PropTypes.func,
};

export default AddObra;
