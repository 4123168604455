// material-ui
import { Typography } from "@mui/material";

// project-imports
import MainCard from "components/MainCard";
import LogoIcon from "components/logo/LogoIcon";
import arrow from "assets/images/arrow.png";
import Login3 from "./login3"
// ==============================|| SAMPLE PAGE ||============================== //

const FinishRegister = () => (
 
  <Login3 />
  
);

export default FinishRegister;
