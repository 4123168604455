import axios from 'utils/axios';
import { dispatch } from 'store';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  users: [],
  selectedUser: null,
  error: null,
  loading: false
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveUserList(state, action) {
      state.users = action.payload;
    },

    selectUser(state, action) {
      state.selectedUser = action.payload;
    },

    createUser(state, action) {
      state.users = [...state.users, action.payload];
    },

    updateUser(state, action) {
      const updatedUser = action.payload;
      state.users = state.users.map(user => user.id === updatedUser.id ? updatedUser : user);
    },

    deleteUser(state, action) {
      state.users = state.users.filter(user => user.id !== action.payload.userId);
    },

    setError(state, action) {
      state.error = action.payload;
    },

    setLoading(state, action) {
      state.loading = action.payload;
    }
  }
});

export default user.reducer;

export const {
  saveUserList,
  selectUser,
  createUser,
  updateUser,
  deleteUser,
  setError,
  setLoading
} = user.actions;

// Thunk action creators for async logic

export function saveUsers(ulist) {
  return async () => {
    try {
      dispatch(saveUserList(ulist));
    } catch (error) {
      dispatch(setError(error));
    }
  };
}


export function addUser(newUser) {
  return async () => {
    try {
      const response = await axios.post('/api/users', newUser);
      dispatch(createUser(response.data));
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function updateUserDetails(updatedUser) {
  return async () => {
    try {
      const response = await axios.put(`/api/users/${updatedUser.id}`, updatedUser);
      dispatch(updateUser(response.data));
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function removeUser(userId) {
  return async () => {
    try {
      await axios.delete(`/api/users/${userId}`);
      dispatch(deleteUser({ userId }));
    } catch (error) {
      dispatch(setError(error));
    }
  };
}
