import { useMemo, useState, useEffect } from "react";

// material-ui
import {
  Grid,
  Stack,
  useMediaQuery,
  Button,
  FormControl,
  Select,
  MenuItem,
  Box,
  Dialog,
  Slide,
  Pagination,
  Typography,
} from "@mui/material";

// project-imports
import { PopupTransition } from "components/@extended/Transitions";
import EmptyUserCard from "components/cards/skeleton/EmptyUserCard";
import CustomerCard from "sections/apps/customer/CustomerCard";
import AddCustomer from "sections/apps/customer/AddCustomer";

import makeData from "data/react-table";
import { GlobalFilter } from "utils/react-table";
import usePagination from "hooks/usePagination";

import Logo from "assets/images/logodark.png";
// assets
import { Add } from "iconsax-react";
import AddObra from "sections/apps/customer/AddObra";
import useAuth from "hooks/useAuth";
import MainCard from "components/MainCard";
import Theme from "themes/theme";

// constant
const allColumns = [
  {
    id: 1,
    header: "Default",
  },
  {
    id: 2,
    header: "Customer Name",
  },
  {
    id: 3,
    header: "Email",
  },
  {
    id: 4,
    header: "Contact",
  },
  {
    id: 5,
    header: "Age",
  },
  {
    id: 6,
    header: "Country",
  },
  {
    id: 7,
    header: "Status",
  },
];

// ==============================|| CUSTOMER - CARD ||============================== //

const ObrasGravadas = () => {
  const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { user } = useAuth();
  let data = user?.obras || [];
  const [sortBy, setSortBy] = useState("Default");
  const [globalFilter, setGlobalFilter] = useState("");
  const [add, setAdd] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [userCard, setUserCard] = useState([]);
  const [page, setPage] = useState(1);
  const handleChange = (event) => {
    setSortBy(event.target.value);
  };

  const handleAdd = () => {
    setAdd(!add);
    if (customer && !add) setCustomer(null);
  };

  // search
  useEffect(() => {
    const newData = data.filter((value) => {
      if (globalFilter) {
        return value.fatherName
          .toLowerCase()
          .includes(globalFilter.toLowerCase());
      } else {
        return value;
      }
    });
    /// setUserCard(newData);
  }, [globalFilter, data]);

  const PER_PAGE = 6;

  const count = Math.ceil(userCard.length / PER_PAGE);
  const _DATA = usePagination(userCard, PER_PAGE);

  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <>
      <Box sx={{ position: "relative", marginBottom: 3 }}>
        <Stack direction="row" alignItems="center">
          <Stack
            direction={matchDownSM ? "column" : "row"}
            sx={{ width: "100%" }}
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <GlobalFilter
              preGlobalFilteredRows={data}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
            <Stack
              direction={matchDownSM ? "column" : "row"}
              alignItems="center"
              spacing={1}
            >
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={handleAdd}
                size="large"
              >
                Adicionar Obra
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <Grid container spacing={3}>
        {user?.obras?.length > 0 ? (
          user?.obras.map((user, index) => (
            <Slide key={index} direction="up" in={true} timeout={50}>
              <Grid item xs={12} sm={6} lg={4}>
                <ObraCard obra={user} />
              </Grid>
            </Slide>
          ))
        ) : (
          <EmptyUserCard
            title={user.name + ", você ainda não gravou nenhuma obra."}
          />
        )}
      </Grid>
      <Stack spacing={2} sx={{ p: 2.5 }} alignItems="flex-end">
        <Pagination
          count={count}
          size="medium"
          page={page}
          showFirstButton
          showLastButton
          variant="combined"
          color="primary"
          onChange={handleChangePage}
        />
      </Stack>

      {/* add customer dialog */}
      <Dialog
        maxWidth="sm"
        fullWidth
        TransitionComponent={PopupTransition}
        onClose={handleAdd}
        open={add}
        sx={{ "& .MuiDialog-paper": { p: 0 } }}
      >
        <AddObra customer={customer} onCancel={handleAdd} />
      </Dialog>
    </>
  );
};

export default ObrasGravadas;

const ObraCard = ({ obra }) => {
  return (
    <MainCard border={false} sx={{ height: "100%" }}>
      <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
      <Typography variant="h6">{obra.title}</Typography>
      <Typography variant="caption" color="textSecondary">
        {obra.subtitle}
      </Typography>
      <img
        width={"100%"}
        style={{ width: 200,marginTop:10,borderRadius:10 }}
        src={obra.picture || Logo}
      ></img>
      <AudioPlayer base64Audio={obra.mp3} />
      </div>
    </MainCard>
  );
};



const AudioPlayer = ({ base64Audio }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  
  // Function to handle toggling play/pause
  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  // Decode base64 to create a URL for the audio source
  const audioSrc = `${base64Audio}`;
  
  return (
    <div style={{marginTop:10}}>
    
      <audio controls>
        <source src={audioSrc} type="audio/mpeg" />
        Your browser does not support the audio tag.
      </audio>

     
    </div>
  );
};

