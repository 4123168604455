import {
    serverCallDelete,
    serverCallGet,
    serverCallPost,
    serverCallURL,
    serverCallPut,
  } from "./serverCall";
  
  export const APICall = {
    verify: () => {
      return serverCallGet("/auth/verify");
    },
    oneTapGoogleSignIn:(tokenId)=>{
      return serverCallPost("/auth/login-gmail",{tokenId})
    },
    register:({email,name,password})=>{
      return serverCallPost("/auth/register",{email,name,password})
    },
    login:({email,password})=>{
      return serverCallPost("/auth/login",{email,password})
    },
    forgotPassword:(email)=>{
      return serverCallPost("/auth/forgot-pass",{email})
    },
    resetPassword:({password,id})=>{
      return serverCallPost("/auth/reset-pass",{password,id})
    },
    completeRegistration:({ tipoConta,
      nomeArtistico,
      cpf,
      dataNascimento,
      nacionalidade,
      endereco,
      telefone,
      cnpj,})=>{
      return serverCallPut(`/auth/complete-registration/`,{ tipoConta,
        nomeArtistico,
        cpf,
        dataNascimento,
        nacionalidade,
        endereco,
        telefone,
        cnpj,})
    },
    getAllUsersAdmin:()=>{
      return serverCallGet(`/auth/admin/all-users`)
    },
  addObra:({obra})=>{
    return serverCallPut(`/auth/add-obra`,{obra})
  },

   

  };