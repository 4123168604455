import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// material-ui
import {
  Alert,
  Box,
  Button,
  Grid,
  InputLabel,
  OutlinedInput,
  Radio,
  Stack,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  MenuItem,
  Select,
  FormGroup,
  Checkbox,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

// third-party
import OtpInput from "react18-input-otp";

// project-imports
import AuthCard from "sections/auth/AuthCard";
import Logo from "components/logo";
import useAuth from "hooks/useAuth";
import AuthSocButton from "sections/auth/AuthSocButton";
import AuthWrapper3 from "sections/auth/AuthWrapper3";

import { ThemeMode } from "config";

// assets
import { User, Profile2User, MusicPlay } from "iconsax-react";
import imgSms from "assets/images/auth/sms.svg";
import imgFacebook from "assets/images/auth/facebook.svg";
import imgTwitter from "assets/images/auth/twitter.svg";
import imgGoogle from "assets/images/auth/google.svg";
import { PatternFormat } from "react-number-format";
import { APICall } from "api";
import bar from "assets/images/media.png";
import supply from "assets/images/supply.png";
import suppl from "assets/images/cover.png";
import Confetti from 'react-confetti'


const steps = ["1", "2", "3", "4"];

function StepWrapper({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

StepWrapper.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  index: PropTypes.string,
};

// ================================|| LOGIN ||================================ //

const Login3 = () => {
  const theme = useTheme();
  const { completeRegister, user } = useAuth();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [selectedValue, setSelectedValue] = useState("Criador");
  const [otp, setOtp] = useState();
  const [criadorButtonDisabled, setCriadorButtonDisabled] = useState(false);
  const [editoraButtonDisabled, setEditoraButtonDisabled] = useState(false);
  const navigate = useNavigate();
  const borderColor =
    theme.palette.mode === ThemeMode.DARK
      ? theme.palette.secondary[200]
      : theme.palette.secondary.light;

  const isStepSkipped = (step) => skipped.has(step);

  const handleNext = (index) => {
    if (typeof index === "number") return setActiveStep(index);
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = (index) => {
    if (typeof index === "number") return setActiveStep(index);

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const [criadorForm, setCriadorForm] = useState({
    nomeArtistico: "",
    cpf: "",
    dataNascimento: "",
    nacionalidade: "",
    endereco: "",
    telefone: "",
    // Add more fields as needed for the Criador form
  });

  const [editoraForm, setEditoraForm] = useState({
    nomeEditora: "",
    cnpj: "",
    enderecoEditora: "",
    telefoneEditora: "",
    // Add more fields as needed for the Editora form
  });

  // Existing code...

  const handleCreateCriador = async () => {
    setCriadorButtonDisabled(true);

    return new Promise((resolve, reject) => {
      if (
        criadorForm.nomeArtistico === "" ||
        criadorForm.endereco === "" ||
        criadorForm.telefone === "" ||
        criadorForm.cpf === "" ||
        criadorForm.nacionalidade === ""
      ) {
        // Show an alert for the empty values
        alert("Todos os campos são obrigatórios.");
        setCriadorButtonDisabled(false);
        return reject(); // Resolve the promise but keep the button disabled
      }

      console.log("Criador Form Values:", criadorForm);
      APICall.completeRegistration({
        tipoConta: "Criador",
        nomeArtistico: criadorForm.nomeArtistico,
        endereco: criadorForm.endereco,
        telefone: criadorForm.telefone,
        cpf: criadorForm.cpf,
        nacionalidade: criadorForm.nacionalidade,
      }).then((res) => {
        console.log("res", res);

        resolve();
        setCriadorButtonDisabled(false);
        setTimeout(() => {
          completeRegister(res.user);
        }, 1900);
      });
    });
    // Here you can perform necessary actions with the Criador form data
  };

  const handleCreateEditora = () => {
    // Here you can perform necessary actions with the Editora form data
    setEditoraButtonDisabled(true);

    return new Promise((resolve, reject) => {
      console.log("Editora Form Values:", editoraForm);

      if (
        editoraForm.nomeEditora === "" ||
        editoraForm.enderecoEditora === "" ||
        editoraForm.telefoneEditora === "" ||
        editoraForm.cnpjcpf === ""
      ) {
        // Show an alert for the empty values
        alert("Todos os campos são obrigatórios.");
        setEditoraButtonDisabled(false);
        return reject(); // Resolve the promise but keep the button disabled
      }

      APICall.completeRegistration({
        tipoConta: "Editora",
        nomeArtistico: editoraForm.nomeEditora,
        endereco: editoraForm.enderecoEditora,
        telefone: editoraForm.telefoneEditora,
        cnpj: editoraForm.cnpj,
      }).then((res) => {
        console.log("res", res);

        resolve();
        setEditoraButtonDisabled(false);
        setTimeout(() => {
          completeRegister(res.user);
        }, 1900);
      });
    });
  };

  // Functions to update form data
  const handleCriadorInputChange = (e) => {
    const { name, value } = e.target;
    setCriadorForm({ ...criadorForm, [name]: value });
  };

  const handleEditoraInputChange = (e) => {
    const { name, value } = e.target;
    setEditoraForm({ ...editoraForm, [name]: value });
  };
  useEffect(() => {
    if (user && user.hasCompletedRegistration) {
      navigate("/menu-principal");
    }
  }, [user]);

  return (
    <div>
      <Grid
        container
        spacing={3}
        sx={{ minHeight: "100%", alignContent: "space-between" }}
      >
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <div />
            <Typography color="secondary">
              Etapa
              <Typography
                component="span"
                variant="subtitle1"
                sx={{ display: "inline-block", margin: "0 5px" }}
              >
                {selectedValue === "Criador"
                  ? activeStep == 1
                    ? activeStep + 1
                    : activeStep == 0
                    ? 1
                    : activeStep
                  : activeStep > 0
                  ? activeStep
                  : activeStep + 1}
              </Typography>
              de 2
            </Typography>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ "& > div": { margin: "24px auto", maxWidth: 800 } }}
        >
          <AuthCard border={false}>
            {activeStep === steps.length ? (
              <>
                <Confetti
      width={"100%"}
      height={"100%"}
    />
                <Alert sx={{ my: 3 }}>
                  Cadastro finalizado! Redirecionando para o menu principal...
                </Alert>
                <Button
                  component={Link}
                  to={"/menu-principal"}
                  color="primary"
                  variant="contained"
                  fullWidth
                >
                  Ir para Menu Principal
                </Button>
              </>
            ) : (
              <>
                <StepWrapper value={activeStep} index={0}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <Stack spacing={1}>
                        <Typography variant="h3">What is your role?</Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          "& .MuiFormLabel-root": {
                            cursor: "pointer",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",

                            border: `1px solid ${theme.palette.divider}`,
                            borderRadius: 1,
                            paddingBottom: 2,
                          },
                          "& .MuiRadio-root.Mui-checked + .MuiFormLabel-root": {
                            boxShadow: `0 0 0 1px ${theme.palette.primary.main}, 0px 8px 24px rgba(27, 46, 94, 0.12)`,
                            borderColor: theme.palette.primary.main,
                            color: theme.palette.primary.main,
                            bgcolor: theme.palette.primary.lighter,
                          },
                        }}
                      >
                        <Grid
                          item
                          sm={4}
                          xs={4}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Radio
                            id="radioCriador"
                            checked={selectedValue === "Criador"}
                            onChange={handleChange}
                            value="Criador"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "A" }}
                            sx={{ display: "none" }}
                          />
                          <InputLabel
                            htmlFor="radioCriador"
                            sx={{
                              ml: "0 !important",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <img
                              width="100%"
                              src={bar}
                              style={{
                                padding: 0,
                              }}
                            />
                            <Typography
                              variant="h4"
                              sx={{
                                mt: 3,
                                color: "black",
                                textAlign: "center",
                              }}
                            >
                              I own a Bar
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                mt: 1,
                                color: "black",
                                textAlign: "center",
                                whiteSpace: "normal", // Ensures wrapping
                                overflowWrap: "break-word", // Ensures long words break
                                padding: "0 10px",
                              }}
                            >
                              Manage & optimize your inventory in real-time.
                            </Typography>
                          </InputLabel>
                        </Grid>

                        <Grid item sm={4} xs={4}>
                          <Radio
                            id="radioEditora"
                            checked={selectedValue === "Editora"}
                            onChange={handleChange}
                            value="Editora"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "B" }}
                            sx={{ display: "none" }}
                          />
                          <InputLabel
                            htmlFor="radioEditora"
                            sx={{
                              ml: "0 !important",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <img
                              width="100%"
                              src={supply}
                              style={{
                                padding: 0,
                              }}
                            />
                            <Typography
                              variant="h4"
                              sx={{
                                mt: 3,
                                color: "black",
                                textAlign: "center",
                              }}
                            >
                              I am a Supplier
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                mt: 1,
                                color: "black",
                                textAlign: "center",
                                whiteSpace: "normal", // Ensures wrapping
                                overflowWrap: "break-word", // Ensures long words break
                                padding: "0 10px",
                              }}
                            >
                              Showcase your products and manage your campaigns
                              with ease.
                            </Typography>
                          </InputLabel>
                        </Grid>
                        <Grid
                          item
                          sm={4}
                          xs={4}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Radio
                            id="radioEdit"
                            checked={selectedValue === "Importer"}
                            onChange={handleChange}
                            value="Importer"
                            name="radio-buttons"
                            inputProps={{ "aria-label": "A" }}
                            sx={{ display: "none" }}
                          />
                          <InputLabel
                            htmlFor="radioEdit"
                            sx={{
                              ml: "0 !important",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <img
                              width="100%"
                              src={suppl}
                              style={{
                                padding: 0,
                              }}
                            />
                            <Typography
                              variant="h4"
                              sx={{
                                mt: 3,
                                color: "black",
                                textAlign: "center",
                              }}
                            >
                              I am an Importer
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                mt: 1,
                                color: "black",
                                textAlign: "center",
                                whiteSpace: "normal", // Ensures wrapping
                                overflowWrap: "break-word", // Ensures long words break
                                padding: "0 10px",
                              }}
                            >
                              Streamline your import operations and manage your
                              product portfolio.
                            </Typography>
                          </InputLabel>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={1}>
                        <Button
                          onClick={() => {
                            if (selectedValue === "Criador") {
                              handleNext(1);
                            } else if (selectedValue === "Editora") {
                              handleNext(2);
                            } else if (selectedValue === "Importer") {
                              handleNext(3);
                            }
                          }}
                          variant="contained"
                          color="primary"
                          fullWidth
                        >
                          Continue
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </StepWrapper>
                <StepWrapper value={activeStep} index={1}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sx={{}}>
                      <Stack spacing={1}>
                        <Typography variant="h3">
                          Tell us about your bar
                        </Typography>
                        <Typography>
                          Forneça suas informações pessoais
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item sm={12} xs={12}>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value="own"
                              control={<Radio />}
                              label="I own a Bar"
                            />
                            <FormControlLabel
                              value="aboutToOwn"
                              control={<Radio />}
                              label="I am about to open a Bar"
                            />
                          </RadioGroup>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="nome-artistico">
                              Bar's Name
                            </InputLabel>
                            <OutlinedInput
                              id="nome-artistico"
                              type="text"
                              placeholder="Bar's Name"
                              fullWidth
                              value={criadorForm.nomeArtistico}
                              onChange={handleCriadorInputChange}
                              name="nomeArtistico"
                            />
                          </Stack>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel>Contact Phone</InputLabel>
                            <PatternFormat
                              format="+## ### ### ##"
                              mask="-"
                              fullWidth
                              customInput={TextField}
                              placeholder="Contact Phone"
                              value={criadorForm.cpf}
                              onChange={handleCriadorInputChange}
                              name="cpf"
                            />
                          </Stack>
                        </Grid>
                        {/* <Grid item sm={6} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="data-nascimento">
                              Data de Nascimento
                            </InputLabel>
                            <OutlinedInput
                              id="data-nascimento"
                              type="date"
                              fullWidth
                              value={criadorForm.dataNascimento}
                              onChange={handleCriadorInputChange}
                              name="dataNascimento"
                            />
                          </Stack>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="nacionalidade">
                              Nacionalidade
                            </InputLabel>
                            <OutlinedInput
                              id="nacionalidade"
                              type="text"
                              placeholder="Nacionalidade"
                              fullWidth
                              value={criadorForm.nacionalidade}
                              onChange={handleCriadorInputChange}
                              name="nacionalidade"
                            />
                          </Stack>
                        </Grid> */}
                        <Grid item sm={12} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="email">
                              Email of Establishment
                            </InputLabel>
                            <OutlinedInput
                              id="email"
                              type="text"
                              placeholder="Email of Establishment"
                              fullWidth
                              value={criadorForm.email}
                              onChange={handleCriadorInputChange}
                              name="email"
                            />
                          </Stack>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="endereco">
                              Physical Address
                            </InputLabel>
                            <OutlinedInput
                              id="endereco"
                              type="text"
                              placeholder="Physical Address"
                              fullWidth
                              value={criadorForm.endereco}
                              onChange={handleCriadorInputChange}
                              name="endereco"
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack spacing={1}>
                            <InputLabel>Business ID Number</InputLabel>
                            <OutlinedInput
                              id="telefone"
                              type="text"
                              placeholder="Business ID Number"
                              fullWidth
                              value={criadorForm.telefone}
                              onChange={handleCriadorInputChange}
                              name="telefone"
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack spacing={1}>
                            <InputLabel>Type of Bar</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              fullWidth
                              value={criadorForm.sexo}
                              disabled
                              onChange={handleCriadorInputChange}
                              name="sexo"
                            ></Select>
                          </Stack>
                        </Grid>

                        {/* <Grid item sm={6} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="documentos-suporte1">
                              Documentos de Suporte
                            </InputLabel>
                            <OutlinedInput
                              id="documentos-suporte1"
                              type="text"
                              placeholder="Documentos de Suporte"
                              fullWidth
                            />
                          </Stack>
                        </Grid> */}
                        {/* <Grid item sm={6} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="documentos-suporte2">
                              Documentos de Suporte
                            </InputLabel>
                            <OutlinedInput
                              id="documentos-suporte2"
                              type="text"
                              placeholder="Documentos de Suporte"
                              fullWidth
                            />
                          </Stack>
                        </Grid> */}
                        <Grid item sm={12} xs={12}>
                          <Stack direction="row" spacing={1}>
                            <Button
                              color="secondary"
                              variant="outlined"
                              onClick={handleBack}
                              fullWidth
                              disabled={criadorButtonDisabled}
                            >
                              Go Back
                            </Button>
                            <Button
                              onClick={() => {
                                handleCreateCriador()
                                  .then(() => {
                                    handleNext(3);
                                  })
                                  .catch(() => {
                                    console.log("erro");
                                  });
                              }}
                              variant="contained"
                              disabled={criadorButtonDisabled}
                              color="primary"
                              fullWidth
                            >
                              Finish
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </StepWrapper>
                <StepWrapper value={activeStep} index={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sx={{}}>
                      <Stack spacing={1}>
                        <Typography variant="h3">
                          Tell us about your business
                        </Typography>
                        <Typography>
                          Please fill in your business’s data so you can begin
                          managing your inventory.
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item sm={6} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="nome-artistico">
                              Business`s Name
                            </InputLabel>
                            <OutlinedInput
                              id="nome-artistico"
                              type="text"
                              placeholder="Bar's Name"
                              fullWidth
                              value={criadorForm.nomeArtistico}
                              onChange={handleCriadorInputChange}
                              name="nomeArtistico"
                            />
                          </Stack>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel>
                              Business Registration Number
                            </InputLabel>
                            <PatternFormat
                              format="##########"
                              mask="-"
                              fullWidth
                              customInput={TextField}
                              placeholder="Business Registration Number"
                              value={criadorForm.numero}
                              onChange={handleCriadorInputChange}
                              name="numero"
                            />
                          </Stack>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="email">
                              Email of Establishment
                            </InputLabel>
                            <OutlinedInput
                              id="email"
                              type="text"
                              placeholder="Email of Establishment"
                              fullWidth
                              value={criadorForm.email}
                              onChange={handleCriadorInputChange}
                              name="email"
                            />
                          </Stack>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel>Contact Phone</InputLabel>
                            <PatternFormat
                              format="+## ### ### ##"
                              mask="-"
                              fullWidth
                              customInput={TextField}
                              placeholder="Contact Phone"
                              value={criadorForm.cpf}
                              onChange={handleCriadorInputChange}
                              name="cpf"
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack spacing={1}>
                          <InputLabel htmlFor="endereco-editora">
                            What items does your business supply?
                            </InputLabel>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox />}
                                label="Alcoholic Beverages"
                              />
                              <FormControlLabel
                                control={<Checkbox />}
                                label="Food Ingredients"
                              />
                              <FormControlLabel
                                
                                control={<Checkbox />}
                                label="Non-Alcoholic Beverages"
                              />
                              <FormControlLabel
                               
                                control={<Checkbox />}
                                label="Snacks"
                              />
                            </FormGroup>
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="endereco-editora">
                              Others
                            </InputLabel>
                            <OutlinedInput
                              id="endereco-editora"
                              type="text"
                              placeholder="Others"
                              fullWidth
                              value={editoraForm.enderecoEditora}
                              onChange={handleEditoraInputChange}
                              name="enderecoEditora"
                            />
                          </Stack>
                        </Grid>


                        <Grid item xs={12}>
                          <Stack direction="row" spacing={1}>
                            <Button
                              color="secondary"
                              variant="outlined"
                              onClick={() => handleBack(0)}
                              fullWidth
                              disabled={editoraButtonDisabled}
                            >
                              Go Back
                            </Button>
                            <Button
                              onClick={() => {
                                handleCreateEditora()
                                  .then(() => {
                                    handleNext();
                                  })
                                  .catch(() => {
                                    console.log("erro");
                                  });
                              }}
                              variant="contained"
                              color="primary"
                              fullWidth
                              disabled={editoraButtonDisabled}
                            >
                              Finish
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </StepWrapper>
                <StepWrapper value={activeStep} index={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sx={{}}>
                      <Stack spacing={1}>
                        <Typography variant="h3">
                          Tell us about your business
                        </Typography>
                        <Typography>
                          Please fill in your business’s data so you can begin
                          managing your inventory.
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item sm={6} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="nome-artistico">
                              Business`s Name
                            </InputLabel>
                            <OutlinedInput
                              id="nome-artistico"
                              type="text"
                              placeholder="Bar's Name"
                              fullWidth
                              value={criadorForm.nomeArtistico}
                              onChange={handleCriadorInputChange}
                              name="nomeArtistico"
                            />
                          </Stack>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel>
                              Business Registration Number
                            </InputLabel>
                            <PatternFormat
                              format="##########"
                              mask="-"
                              fullWidth
                              customInput={TextField}
                              placeholder="Business Registration Number"
                              value={criadorForm.numero}
                              onChange={handleCriadorInputChange}
                              name="numero"
                            />
                          </Stack>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="email">
                              Email of Establishment
                            </InputLabel>
                            <OutlinedInput
                              id="email"
                              type="text"
                              placeholder="Email of Establishment"
                              fullWidth
                              value={criadorForm.email}
                              onChange={handleCriadorInputChange}
                              name="email"
                            />
                          </Stack>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <Stack spacing={1}>
                            <InputLabel>Contact Phone</InputLabel>
                            <PatternFormat
                              format="+## ### ### ##"
                              mask="-"
                              fullWidth
                              customInput={TextField}
                              placeholder="Contact Phone"
                              value={criadorForm.cpf}
                              onChange={handleCriadorInputChange}
                              name="cpf"
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack spacing={1}>
                          <InputLabel htmlFor="endereco-editora">
                            What items does your business supply?
                            </InputLabel>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox />}
                                label="Alcoholic Beverages"
                              />
                              <FormControlLabel
                                control={<Checkbox />}
                                label="Food Ingredients"
                              />
                              <FormControlLabel
                                
                                control={<Checkbox />}
                                label="Non-Alcoholic Beverages"
                              />
                              <FormControlLabel
                               
                                control={<Checkbox />}
                                label="Snacks"
                              />
                            </FormGroup>
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="endereco-editora">
                              Others
                            </InputLabel>
                            <OutlinedInput
                              id="endereco-editora"
                              type="text"
                              placeholder="Others"
                              fullWidth
                              value={editoraForm.enderecoEditora}
                              onChange={handleEditoraInputChange}
                              name="enderecoEditora"
                            />
                          </Stack>
                        </Grid>


                        <Grid item xs={12}>
                          <Stack direction="row" spacing={1}>
                            <Button
                              color="secondary"
                              variant="outlined"
                              onClick={() => handleBack(0)}
                              fullWidth
                              disabled={editoraButtonDisabled}
                            >
                              Go Back
                            </Button>
                            <Button
                              onClick={() => {
                                handleCreateEditora()
                                  .then(() => {
                                    handleNext();
                                  })
                                  .catch(() => {
                                    console.log("erro");
                                  });
                              }}
                              variant="contained"
                              color="primary"
                              fullWidth
                              disabled={editoraButtonDisabled}
                            >
                              Finish
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </StepWrapper>
              </>
            )}
          </AuthCard>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12}>
            {activeStep != steps.length && (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="baseline"
                sx={{ mb: { xs: -0.5, sm: 0.5 } }}
              >
               
              </Stack>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login3;
