// material-ui


// project-imports
import MainCard from "components/MainCard";
import LogoIcon from "components/logo/LogoIcon";
import arrow from "assets/images/arrow.png";
import React, { useState } from "react";
import { Card, CardContent, Typography, Button, IconButton, TextField, Box, Grid  } from "@mui/material";
import { border, borderColor } from "@mui/system";




// ==============================|| SAMPLE PAGE ||============================== //

const Inventory = () => {
    const products = [
        { id: 1, name: "Absolut Vodka", price: "€19,20", details: "1 ud · 1L · Vodka · Alc. Vol 40%", image: "https://via.placeholder.com/80" },
        { id: 2, name: "Chivas Regal", price: "€39,50", details: "1 ud · 700ml · Whisky · Alc. Vol 40%", image: "https://via.placeholder.com/80" },
        { id: 3, name: "Johnnie Walker", price: "€45,00", details: "1 ud · 1L · Whisky · Alc. Vol 40%", image: "https://via.placeholder.com/80" },
        { id: 4, name: "Smirnoff Vodka", price: "€15,80", details: "1 ud · 1L · Vodka · Alc. Vol 40%", image: "https://via.placeholder.com/80" },
        { id: 5, name: "Jack Daniel's", price: "€49,90", details: "1 ud · 700ml · Whisky · Alc. Vol 40%", image: "https://via.placeholder.com/80" },
        { id: 6, name: "Absolut Vodka", price: "€19,20", details: "1 ud · 1L · Vodka · Alc. Vol 40%", image: "https://via.placeholder.com/80" },
        { id: 7, name: "Chivas Regal", price: "€39,50", details: "1 ud · 700ml · Whisky · Alc. Vol 40%", image: "https://via.placeholder.com/80" },
        { id: 8, name: "Johnnie Walker", price: "€45,00", details: "1 ud · 1L · Whisky · Alc. Vol 40%", image: "https://via.placeholder.com/80" },
        { id: 9, name: "Smirnoff Vodka", price: "€15,80", details: "1 ud · 1L · Vodka · Alc. Vol 40%", image: "https://via.placeholder.com/80" },
        { id: 10, name: "Jack Daniel's", price: "€49,90", details: "1 ud · 700ml · Whisky · Alc. Vol 40%", image: "https://via.placeholder.com/80" },
          
      ];

    return (
  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      height: "40vh",
      alignItems: "flex-start",
      width: "100%",
      
      flexDirection: "column",
    }}
  >
    {/* <MainCard title="Em Desenvolvimento">
    <Typography variant="body1">
      Nos próximos dias estaremos disponibilizando novas funcionalidades.
    </Typography>
  </MainCard> */}
    <div>
    <Grid container spacing={3} columns={{ xs: 4, sm: 8, md: 12 }} style={{ padding: "16px" }}>
      
        <Grid item xs={12} sm={6} md={3}>
            <Box style={{ backgroundColor: "#313A13", borderColor: "#313A13", padding: "16px", borderRadius: "12px", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}>
                <Typography variant="h4" color={"#FFFFFF"}>Total Products</Typography>
                <Typography variant="h4" color={"#FFFFFF"}>120</Typography>
            </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
            <Box style={{ backgroundColor: "#FBFAFE", borderColor: "#313A13", padding: "16px", borderRadius: "12px", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}>
                <Typography variant="h4" color={"#313A13"}>Total Products</Typography>
                <Typography variant="h4" color={"#313A13"}>120</Typography>
            </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
            <Box style={{ backgroundColor: "#FBFAFE", borderColor: "#313A13", padding: "16px", borderRadius: "12px", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}>
                <Typography variant="h4" color={"#313A13"}>Total Products</Typography>
                <Typography variant="h4" color={"#313A13"}>120</Typography>
            </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
            <Box style={{ backgroundColor: "#FBFAFE", borderColor: "#313A13", padding: "16px", borderRadius: "12px", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}>
                <Typography variant="h4" color={"#313A13"}>Total Products</Typography>
                <Typography variant="h4" color={"#313A13"}>120</Typography>
            </Box>
        </Grid>


    </Grid>
        <Typography variant="h3" sx ={{ml: "16px" }}>
            Your Inventory
        </Typography>
        <Typography variant="h6" sx={{ml: "16px" }}>
        This is a general summary of your current inventory. Here you can see the total number of products, the items you have in stock, the best sellers and the least consumed. Use this view to maintain control of your inventory, manage on-hand quantities, and adjust prices to optimize your sales.
        </Typography>

        <Grid container spacing={3} columns={{ xs: 4, sm: 8, md: 10 }} style={{ padding: "16px" }}>
      {products.map((product) => (
        <Grid item xs={12} sm={6} md={2} key={product.id}>
          <ProductCard
            name={product.name}
            price={product.price}
            details={product.details}
            image={product.image}
          />
        </Grid>
      ))}
    </Grid>

    </div>
  </div>
  )
}



const ProductCard = ({name, price, details, image}) => {
  const [quantity, setQuantity] = useState(1);

  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) setQuantity(quantity - 1);
  };

  return (
    <Card
      style={{
        
        padding: "16px",
        display: "flex",
        height: "300px",
        margin: "8px",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "12px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      {/* Top Right Icon */}
      <IconButton style={{ alignSelf: "flex-end", padding: "4px" }}>
        
      </IconButton>

      {/* Product Image */}
      <img
        src={image} // Replace with actual image URL
        alt="Product"
        style={{ width: "80px", height: "80px", objectFit: "contain", marginBottom: "8px" }}
      />

      {/* Price */}
      <Typography variant="h6" color="textPrimary" style={{ fontWeight: "bold" }}>
        {price}
      </Typography>

      {/* Product Name */}
      <Typography variant="subtitle1" style={{ fontWeight: "bold", textAlign: "center" }}>
        {name}
      </Typography>

      {/* Product Details */}
      <Typography variant="body2" color="textSecondary" style={{ textAlign: "center" }}>
       {details}
      </Typography>

      {/* Quantity Selector and Add Button */}
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", marginTop: "12px" }}>
        <div style={{ display: "flex", alignItems: "center", border: "1px solid gray", borderRadius: "8px" }}>
          <IconButton size="small" onClick={handleDecrement}>
            -
          </IconButton>
          <TextField
            value={quantity}
            inputProps={{ style: { textAlign: "center", width: "32px" } }}
            style={{ width: "40px" }}
            disabled
          />
          <IconButton size="small" onClick={handleIncrement}>
            +  
          </IconButton>
        </div>
        <Button
          variant="contained"
          style={{ backgroundColor: "#FF5722", color: "#fff", padding: "6px 12px", borderRadius: "8px" }}
        >
          Añadir
        </Button>
      </div>
    </Card>
  );
};


export default Inventory;
