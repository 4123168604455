// project-imports
import ADMIN from "./ADMIN";
import Demanda from "./Demanda";
import Diretorio from "./Diretorio";
import Education from "./Education";
import Ranking from "./Ranking";
import support from "./support";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [
    support,
     //Diretorio,
      //Demanda, 
      //Ranking,
      // Education,
    
      ],
};

export default menuItems;
