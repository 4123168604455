import PropTypes from "prop-types";

// material-ui
import { styled, useTheme } from "@mui/material/styles";
import { Box, Button, Stack, Typography } from "@mui/material";

// third-party
import { useDropzone } from "react-dropzone";

// project-imports
import RejectionFiles from "./RejectionFiles";
import PlaceholderContent from "./PlaceholderContent";
import { useEffect } from "react";
import music from "assets/images/icons/music.png";
const DropzoneWrapper = styled("div")(({ theme }) => ({
  outline: "none",
  overflow: "hidden",
  position: "relative",
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create("padding"),
  backgroundColor: theme.palette.background.paper,
  border: `1px dashed ${theme.palette.secondary.main}`,
  "&:hover": { opacity: 0.72, cursor: "pointer" },
}));

// ==============================|| UPLOAD - SINGLE FILE ||============================== //

const SingleFileUpload = ({ error, file, setFieldValue, sx }) => {
  const theme = useTheme();

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    accept: {
      "audio/*": [],
    },
    // maxSize: 5 * 1024 * 1024, // 5MB in bytes
    multiple: false,
    onDrop: (acceptedFiles) => {
     
    
        acceptedFiles.map((file) => {
          // Read the file content and convert it to Base64
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const base64 = reader.result;
            if (base64) {
              // Add Base64 representation to the file object
              file.base64 = base64;
              // Add the file to the state
              console.log([file]);
              setFieldValue([file])
            }
          };
        })
      
    },
  });

  const thumbs =
    file &&
    file.length > 0 &&
    file.map((item) => (
      <div>
      <img
        key={item?.path}
        alt={item?.path}
        src={music}
        style={{
          top: 8,
          left: 8,
          borderRadius: 2,
          position: "absolute",
          width: "30px",
          height: "30px",
          background: theme.palette.background.paper,
        }}
        onLoad={() => {
          URL.revokeObjectURL(item?.preview);
        }}
      />
      <Typography
        variant="subtitle1"
        sx={{
          top: 8,
          left: 48,
          position: "absolute",
          color: theme.palette.text.primary,
        }}
        >{item.name}</Typography>
      </div>
    ));

  const onRemove = () => {
    setFieldValue( []);
  };

  return (
    <Box sx={{ width: "100%", ...sx }}>
      <DropzoneWrapper
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: "error.main",
            borderColor: "error.light",
            bgcolor: "error.lighter",
          }),
          ...(file && {
            padding: "12% 0",
          }),
        }}
      >
        <input {...getInputProps()} />
       { file?.length == 0 &&<PlaceholderContent />}
        {thumbs}
      </DropzoneWrapper>

      {fileRejections.length > 0 && (
        <RejectionFiles fileRejections={fileRejections} />
      )}

      {file && file.length > 0 && (
        <Stack direction="row" justifyContent="flex-end" sx={{ mt: 1.5 }}>
          <Button variant="contained" color="error" onClick={onRemove}>
            Remover
          </Button>
        </Stack>
      )}
    </Box>
  );
};

SingleFileUpload.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.array,
  setFieldValue: PropTypes.func,
  sx: PropTypes.object,
};

export default SingleFileUpload;
