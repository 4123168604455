import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import format from "date-fns/format";
import { useState } from "react";
import { ArrowLeft, CloseCircle } from "iconsax-react";
import { Box, Checkbox, Button, ButtonGroup, Chip } from "@mui/material";

//import { selectQuickPanelData, selectQuickPanelOpen, toggleQuickPanel } from './quickPanelSlice';

const StyledSwipeableDrawer = styled(SwipeableDrawer)(() => ({
  "& .MuiDrawer-paper": {
    width: 400,
  },
}));

/**
 * The quick panel.
 */
function QuickPanel({ open, handleClick }) {
  const [checked, setChecked] = useState(["notifications"]);
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  return (
    <StyledSwipeableDrawer
      open={open}
      anchor="right"
      onOpen={() => {}}
      onClose={() => handleClick}
      disableSwipeToOpen
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <ArrowLeft />
          <Typography variant="h5">Mi Carrito | Licoteca</Typography>
        </div>
        <div onClick={handleClick} style={{ cursor: "pointer" }}>
          <CloseCircle />
        </div>
      </div>

      <Divider />

      <Box sx={{ width: "100%", maxWidth: 480, bgcolor: "background.paper" }}>
        <List>
          {/* Absolut Vodka */}
          <ListItem>
            <ListItemIcon></ListItemIcon>
            <ListItemText
              secondary={
                <>
                  <Chip
                    label="Importado"
                    color="info"
                    size="small"
                    style={{ marginRight: "10px" }}
                  />
                  <Chip label="-15%" color="success" size="small" />
                  <Typography variant="h5" color="black">
                    Absolute Vodka
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    1 ud 1L | Vodka Alc. Vol 40%
                  </Typography>
				  <Divider />
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}
                  >
                    <ButtonGroup>
                      <Button>-</Button>
                      <Button>112</Button>
                      <Button>+</Button>
                    </ButtonGroup>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        color="gray"
                        sx={{ textDecoration: "line-through" }}
                      >
                        €2520.40
                      </Typography>

                      <Typography variant="h5" color={"black"}>
                        €2150.40
                      </Typography>
                    </div>
                  </Box>
                </>
              }
            />
            {/* <ListItemSecondaryAction>
              <Box>
                <ButtonGroup>
                  <Button>-</Button>
                  <Button>112</Button>
                  <Button>+</Button>
                </ButtonGroup>
                <Typography variant="subtitle1" align="right">
                  €2150.40
                </Typography>
              </Box>
            </ListItemSecondaryAction> */}
          </ListItem>
		  <Divider />

          {/* Amstel Clásica */}
          <ListItem>
            <ListItemIcon></ListItemIcon>
            <ListItemText
              secondary={
                <>
                  <Chip
                    label="2x1"
                    color="success"
                    size="small"
                    style={{ marginRight: "10px" }}
                  />
                  <Typography variant="caption" color="orange">
                    Termina em 00:00:00
                  </Typography>
                  <Typography variant="h5" color="black">
                    Amstel Clásica
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    24 x 33 cl | Cerveza Alc. Vol 3.4%
                  </Typography>
				  <Divider />
				  <Box
                    sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}
                  >
                    <ButtonGroup>
                      <Button>-</Button>
                      <Button>75</Button>
                      <Button>+</Button>
                    </ButtonGroup>
					
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        color="gray"
                        sx={{ textDecoration: "line-through" }}
                      >
                        €854.00
                      </Typography>

                      <Typography variant="h5" color={"black"}>
                        €620.10
                      </Typography>
                    </div>
                  </Box>
                </>
              }
            />
            {/* <ListItemSecondaryAction>
              <Box>
                <ButtonGroup>
                  <Button>-</Button>
                  <Button>75</Button>
                  <Button>+</Button>
                </ButtonGroup>
                <Typography variant="subtitle1" align="right">
                  €620.10
                </Typography>
              </Box>
            </ListItemSecondaryAction> */}
          </ListItem>
		  <Divider />
		  <ListItem>
            <ListItemIcon></ListItemIcon>
            <ListItemText
              secondary={
                <>
                  <Chip
                    label="Importado"
                    color="info"
                    size="small"
                    style={{ marginRight: "10px" }}
                  />
                  <Chip label="-5%" color="success" size="small" />
                  <Typography variant="h5" color="black">
                    1800 Cristalino Añejo
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    24 x 33 cl | Cerveza Alc. Vol 3.4%
                  </Typography>
				  <Divider />
				  <Box
                    sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}
                  >
					
                    <ButtonGroup>
                      <Button>-</Button>
                      <Button>10</Button>
                      <Button>+</Button>
                    </ButtonGroup>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        color="gray"
                        sx={{ textDecoration: "line-through" }}
                      >
                        €854.00
                      </Typography>

                      <Typography variant="h5" color={"black"}>
                        €569.52
                      </Typography>
                    </div>
                  </Box>
                </>
              }
            />
            {/* <ListItemSecondaryAction>
              <Box>
                <ButtonGroup>
                  <Button>-</Button>
                  <Button>75</Button>
                  <Button>+</Button>
                </ButtonGroup>
                <Typography variant="subtitle1" align="right">
                  €620.10
                </Typography>
              </Box>
            </ListItemSecondaryAction> */}
          </ListItem>

          {/* Additional Items */}
          {/* Add similar structures for other products */}
        </List>

        {/* Footer Section */}
        <Box sx={{ p: 2, m: 2, backgroundColor: "#F5F4FB", borderRadius: "8px" }}>
		<Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">Subtotal</Typography>
		  <Typography variant="h6">€3340.02</Typography>
		</Box>
		<Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">Envío</Typography>
		  <Typography variant="h6">€25.00</Typography>
		</Box>
		<Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">IVA</Typography>
		  <Typography variant="h6">€706.65</Typography>
		</Box>
		<Divider sx={{ my: 1 }} />
		<Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" fontWeight="bold">
            Total
          </Typography>
		  <Typography variant="h6" fontWeight="bold">
            €4071.67
          </Typography>
		</Box>
        </Box>
		<Box sx={{ display: "flex", justifyContent: "space-between", mt: 2, p: 2 }}>
            <Button variant="outlined">Programar pedido</Button>
            <Button variant="contained" color="primary">
              Proceder al pago
            </Button>
          </Box>
      </Box>
    </StyledSwipeableDrawer>
  );
}

export default QuickPanel;
