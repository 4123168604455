import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// third-party
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';

// project-imports
import AlertContactDelete from './AlertContactDelete';
import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { ThemeMode } from 'config';

// assets
import { Camera, Trash } from 'iconsax-react';

const avatarImage = require.context('assets/images/users', true);

// constant
const getInitialValues = (contact) => {
  const newContact = {
    firstName: '', // Ajustado para coincidir con "firstName"
    lastName: '',  // Ajustado para "lastName"
    status2: '',   // Ajustado para "status2"
    type: '',      // Sin cambios
    establishment: '', // Sin cambios
  };

  if (contact) {
    newContact.firstName = contact.firstName || ''; // Validación adicional por si faltan datos
    newContact.lastName = contact.lastName || '';
    newContact.status2 = contact.status2 || '';
    newContact.type = contact.type || '';
    newContact.establishment = contact.establishment || '';
    return _.merge({}, newContact, contact); // Combina datos para conservar cualquier otra propiedad de contact
  }

  return newContact;
};

const allStatus = ['Inactive', 'Active'];
const allType = ['Bar', 'Suplier', 'Importer'];

// ==============================|| CONTACT - ADD / EDIT ||============================== //

const AddContact = ({ contact, onCancel }) => {
  const theme = useTheme();
  const isCreating = !contact;

  const [selectedImage, setSelectedImage] = useState(undefined);
  const [avatar, setAvatar] = useState(() => {
    try {
      // Si existe la imagen dinámica, úsala
      return avatarImage(`./avatar-${isCreating && !contact?.avatar ? 1 : contact.avatar}.png`);
    } catch (error) {
      // Si no existe, usa la imagen predeterminada
      return "/assets/images/profile/profile-back-1.png";
    }
  });

  useEffect(() => {
    if (selectedImage) {
      setAvatar(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  // Esquema de validación actualizado
  const ContactSchema = Yup.object().shape({
    firstName: Yup.string().max(255).required('First name is required'),
    lastName: Yup.string().max(255).required('Last name is required'),
    status2: Yup.string().required('Status is required'),
    type: Yup.string().required('Type is required'),
    establishment: Yup.string().max(255).required('Establishment is required'),
    email: Yup.string().max(255).email('Must be a valid email'), // Opcional, pero validado si está presente
    location: Yup.string().max(500) // Opcional
  });


  const [openAlert, setOpenAlert] = useState(false);

  const handleAlertClose = () => {
    setOpenAlert(!openAlert);
    onCancel();
  };

  const formik = useFormik({
    initialValues: getInitialValues(contact),
    validationSchema: ContactSchema,
    onSubmit: (values, { setSubmitting }) => {
      try {
        if (contact) {
          dispatch(
            openSnackbar({
              open: true,
              message: 'Contact updated successfully.',
              variant: 'alert',
              alert: {
                color: 'success'
              },
              close: false
            })
          );
        } else {
          dispatch(
            openSnackbar({
              open: true,
              message: 'Contact added successfully.',
              variant: 'alert',
              alert: {
                color: 'success'
              },
              close: false
            })
          );
        }

        setSubmitting(false);
        onCancel();
      } catch (error) {
        console.error(error);
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle>{contact ? 'Edit Contact' : 'New Contact'}</DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <Stack direction="row" justifyContent="center" sx={{ mt: 3 }}>
                    <FormLabel
                      htmlFor="change-avatar"
                      sx={{
                        position: 'relative',
                        borderRadius: '50%',
                        overflow: 'hidden',
                        '&:hover .MuiBox-root': { opacity: 1 },
                        cursor: 'pointer'
                      }}
                    >
                      <Avatar alt="Avatar 1" src={avatar} sx={{ width: 72, height: 72, border: '1px dashed' }} />
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          backgroundColor: theme.palette.mode === ThemeMode.DARK ? 'rgba(255, 255, 255, .75)' : 'rgba(0,0,0,.65)',
                          width: '100%',
                          height: '100%',
                          opacity: 0,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <Stack spacing={0.5} alignItems="center">
                          <Camera style={{ color: theme.palette.secondary.lighter, fontSize: '2rem' }} />
                          <Typography sx={{ color: 'secondary.lighter' }}>Upload</Typography>
                        </Stack>
                      </Box>
                    </FormLabel>
                    <TextField
                      type="file"
                      id="change-avatar"
                      placeholder="Outlined"
                      variant="outlined"
                      sx={{ display: 'none' }}
                      onChange={(e) => setSelectedImage(e.target.files?.[0])}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="contact-firstName">First Name</InputLabel>
                        <TextField
                          fullWidth
                          id="contact-firstName"
                          placeholder="Enter First Name"
                          {...getFieldProps('firstName')}
                          error={Boolean(touched.firstName && errors.firstName)}
                          helperText={touched.firstName && errors.firstName}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="contact-lastName">Last Name</InputLabel>
                        <TextField
                          fullWidth
                          id="contact-lastName"
                          placeholder="Enter Last Name"
                          {...getFieldProps('lastName')}
                          error={Boolean(touched.lastName && errors.lastName)}
                          helperText={touched.lastName && errors.lastName}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="contact-status2">Status</InputLabel>
                        <FormControl fullWidth>
                          <Select
                            id="contact-status2"
                            displayEmpty
                            {...getFieldProps('status2')}
                            onChange={(event) => setFieldValue('status2', event.target.value)}
                            input={<OutlinedInput placeholder="Select Status" />}
                            renderValue={(selected) => {
                              if (!selected) {
                                return <Typography variant="subtitle1">Select Status</Typography>;
                              }
                              return <Typography variant="subtitle2">{selected}</Typography>;
                            }}
                          >
                            {allStatus.map((status2) => (
                              <MenuItem key={status2} value={status2}>
                                <ListItemText primary={status2} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {touched.status2 && errors.status2 && (
                          <FormHelperText error>{errors.status2}</FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="contact-type">Type</InputLabel>
                        <FormControl fullWidth>
                          <Select
                            id="contact-type"
                            displayEmpty
                            {...getFieldProps('type')}
                            onChange={(event) => setFieldValue('type', event.target.value)}
                            input={<OutlinedInput placeholder="Select Type" />}
                            renderValue={(selected) => {
                              if (!selected) {
                                return <Typography variant="subtitle1">Select Type</Typography>;
                              }
                              return <Typography variant="subtitle2">{selected}</Typography>;
                            }}
                          >
                            {allType.map((type) => (
                              <MenuItem key={type} value={type}>
                                <ListItemText primary={type} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {touched.type && errors.type && (
                          <FormHelperText error>{errors.type}</FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={1.25}>
                        <InputLabel htmlFor="contact-establishment">Establishment</InputLabel>
                        <TextField
                          fullWidth
                          id="contact-establishment"
                          placeholder="Enter Establishment Name"
                          {...getFieldProps('establishment')}
                          error={Boolean(touched.establishment && errors.establishment)}
                          helperText={touched.establishment && errors.establishment}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  {!isCreating && (
                    <Tooltip title="Delete contact" placement="top">
                      <IconButton onClick={() => setOpenAlert(true)} size="large" color="error">
                        <Trash variant="Bold" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Button color="error" onClick={onCancel}>
                      Cancel
                    </Button>
                    <Button variant="contained" type="submit" disabled={isSubmitting}>
                      Save
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
      {contact && <AlertContactDelete open={openAlert} handleClose={handleAlertClose} />}
    </>
  );
};

AddContact.propTypes = {
  contact: PropTypes.shape({
    avatar: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fatherName: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.string
  }),
  onCancel: PropTypes.func
};

export default AddContact;