// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { DocumentCode2, OceanProtocol, Level, ShieldCross, InfoCircle,Rank, I24Support, Driving } from 'iconsax-react';

// icons
const icons = {
  samplePage: DocumentCode2,
  menuLevel: OceanProtocol,
  menuLevelSubtitle: Level,
  disabledMenu: ShieldCross,
  chipMenu: InfoCircle,
  documentation: I24Support,
  roadmap: Driving,
  Ranking:Rank
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const RankingObj = {
  id: 'Ranking',
  title: <FormattedMessage id="Performance" />,
  type: 'group',
  children: [
   
    {
      id: 'ranking',
      title: <FormattedMessage id="Ranking Artistas" />,
      type: 'item',
      url: '/ranking-artistas',
      icon: icons.Ranking,
      disabled: true
    },
    // {
    //   id: 'disabled-menu1',
    //   title: <FormattedMessage id="Melhores Demandas" />,
    //   type: 'item',
    //   url: '/Obras-n-Gravadas ',
    //   icon: icons.disabledMenu,
    //   disabled: true
    // },
  ]
};

export default RankingObj;
